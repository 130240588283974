import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtConfig, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ColorPickerModule as NgxColorPickerModule } from 'ngx-color-picker';
import { AccordionModule } from 'primeng/accordion';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { ClientPageComponent } from './client-page/client-page.component';
import { DisplayPageComponent } from './display-page/display-page.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { DisplayLayoutComponent } from './output-layout/display-layout.component';
import { PasswordRecoveryDialogComponent } from './password-recovery-dialog/password-recovery-dialog.component';
import { PasswordRecoveryPageComponent } from './password-recovery-page/password-recovery-page.component';
import { TranscriptPageComponent } from './transcript-page/transcript-page.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

export function tokenProvider(authService: AuthService): JwtConfig {
  return {
    tokenGetter: () => {
      return authService.getToken();
    },
    allowedDomains: ['localhost:4200'],
    disallowedRoutes: [],
  };
}

export class LocalHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    press: { time: 500 }, //set press delay as 500ms
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AccordionModule,
    AppRoutingModule,
    BlockUIModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ColorPickerModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MenuModule,
    MenubarModule,
    NgxColorPickerModule,
    OrderListModule,
    PanelMenuModule,
    PanelModule,
    PickListModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SelectButtonModule,
    SliderModule,
    TabMenuModule,
    TabViewModule,
    TableModule,
    ToastModule,
    ToggleButtonModule,
    TooltipModule,
    TreeTableModule,
    SplitterModule,
    HammerModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: tokenProvider,
        deps: [AuthService],
      },
    }),
    AuthLayoutComponent,
    ClientPageComponent,
    DisplayLayoutComponent,
    DisplayPageComponent,
    LoginDialogComponent,
    LoginPageComponent,
    MainLayoutComponent,
    PasswordRecoveryDialogComponent,
    PasswordRecoveryPageComponent,
    TranscriptPageComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: LocalHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
