import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: true,
  imports: [RouterLink, TooltipModule, RouterOutlet],
})
export class MainLayoutComponent implements OnInit {
  menuItems: any;

  constructor() {}

  ngOnInit(): void {
    this.menuItems = [
      {
        label: 'Client',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/main/client'],
      },
    ];
  }
}
