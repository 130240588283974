import { APP_BASE_HREF, DOCUMENT, NgClass, NgIf, NgStyle } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WINDOW } from '@ng-web-apis/common';
import * as ace from 'ace-builds';
import { UUID } from 'angular2-uuid';
import { ConfirmationService, MenuItem, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { Listbox } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { SliderModule } from 'primeng/slider';
import { Table, TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import {
  processDelta,
  TextOperation,
  TextTools,
  WebSocketMessage,
  WebSocketMessageDataActivity,
  WebSocketMessageDataDeltaTextUpdate,
  WebSocketMessageDataSpeechTranscript,
  WebSocketMessageDataTextUpdate,
  WEBSOCKET_RECONNECT_DELAY,
} from '../app.common';
import { AuthService } from '../auth/auth.service';
import { LiveSubApiService, SubtitleProxy } from '../live-sub-api.service';
import EditSession = ace.Ace.EditSession;
import Editor = ace.Ace.Editor;
import Delta = ace.Ace.Delta;

export class ClientConfigurationDefaults {
  static readonly DEFAULT_TEXT_EDITOR_FONT_SIZE = 18;
  static readonly DEFAULT_SUBTITLE_TEXT_EDITOR_FONT_SIZE = 18;
  static readonly DEFAULT_SUBTITLE_ROW_COUNT = 2;
  static readonly DEFAULT_PRINT_MARGIN = 37;
  static readonly DEFAULT_WRAP = false;
  static readonly DEFAULT_SHOW_PRINT_MARGIN = true;
  static readonly DEFAULT_SHOW_INVISIBLES = true;
  static readonly DEFAULT_SUBTITLE_ANIMATION = true;
  static readonly DEFAULT_SUBTITLE_CHARACTER_DELAY = 50;
  static readonly DEFAULT_SUBTITLE_AS_INLINE_TEXT = false;
  static readonly DEFAULT_TABLE_SCROLL_HEIGHT = 150;
  static readonly DEFAULT_DOCUMENT_EDITOR = true;
}

export interface ClientConfiguration {
  textEditorFontSize: number;
  subtitleTextEditorFontSize: number;
  subtitleRowCount: number;
  printMargin: number;
  wrap: boolean;
  showPrintMargin: boolean;
  showInvisibles: boolean;
  subtitleAnimation: boolean;
  subtitleCharacterDelay: number;
  subtitleAsInlineText: boolean;
  tableScrollHeight: number;
  documentEditor: boolean;
}

@Component({
  selector: 'app-client-page',
  templateUrl: './client-page.component.html',
  styleUrls: ['./client-page.component.scss'],
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    InputNumberModule,
    InputTextModule,
    MenubarModule,
    SharedModule,
    SliderModule,
    TabViewModule,
    TableModule,
    ToastModule,
    ToggleButtonModule,
    TooltipModule,
    TagModule,
    NgClass,
    NgIf,
    NgStyle,
  ],
})
export class ClientPageComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly LINE_LENGTH_THRESHOLD = 512;
  readonly TEXT_LENGTH_THRESHOLD_TRIGGER = 4096;
  readonly TEXT_LENGTH_THRESHOLD_HIGH = 2024;
  readonly TEXT_LENGTH_THRESHOLD_LOW = 1024;

  readonly FONT_SIZE_MIN = 8;
  readonly FONT_SIZE_MAX = 32;
  readonly FONT_SIZE_STEP = 1;

  readonly SUBTITLE_ROW_COUNT_MIN = 1;
  readonly SUBTITLE_ROW_COUNT_MAX = 6;
  readonly SUBTITLE_ROW_COUNT_STEP = 1;

  readonly MARGIN_MIN = 5;
  readonly MARGIN_MAX = 80;
  readonly MARGIN_STEP = 1;

  readonly SUBTITLE_CHARACTER_DELAY_MIN = 0;
  readonly SUBTITLE_CHARACTER_DELAY_MAX = 200;
  readonly SUBTITLE_CHARACTER_DELAY_STEP = 1;

  readonly TABLE_SCROLL_HEIGHT_MAX = 500;
  readonly TABLE_SCROLL_HEIGHT_MIN = 100;
  readonly TABLE_SCROLL_HEIGHT_STEP = 1;

  readonly AUTO_SAVE_INTERVAL = 100;
  readonly TIMESTAMP_INTERVAL = 1000;
  readonly UNLOCKING_INTERVAL = 1000;

  readonly SPEECH_SAFEGUARD_DELAY = 3000;

  @ViewChild('table') table!: Table;
  @ViewChild('textEditorElement') textEditorElement!: ElementRef<HTMLElement>;
  @ViewChild('subtitleTextEditorElement') subtitleTextEditorElement!: ElementRef<HTMLElement>;
  @ViewChild('subtitleProxyListBox') subtitleProxyListBox!: Listbox;

  speech: string;
  search: string;
  subtitleProxies: SubtitleProxy[];
  selectedSubtitleProxy: SubtitleProxy | null;
  currentSubtitleProxy: SubtitleProxy | null;
  dialogTitleText: string;
  menuBarItems: MenuItem[];
  isNewDialogVisible = false;
  isRenameDialogVisible = false;
  isSettingsDialogVisible = false;
  configuration: ClientConfiguration;
  activity: boolean;

  private currentTextSegmentStoreSubscription$: Subscription | null;
  private resizeObservable$: Observable<Event>;
  private resizeSubscription$: Subscription;
  private speechSafeguardSubscription$: Subscription;
  private subtitleTextUpdateSubscription$: Subscription | null;
  private timerSubscription$: Subscription | null;
  private unlockingSubscription$: Subscription = null;

  private disableSubtitleTextEditorChangeEvents = false;
  private disableTextEditorChangeEvents = false;
  private readonly identifier: string;
  private subtitleInProgress: boolean;
  private subtitleTextEditor: Editor;
  private textEditor: Editor;
  private webSocket: WebSocket;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private authService: AuthService,
    private apiService: LiveSubApiService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private http: HttpClient,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    this.title.setTitle(`LiveSub Client`);
    this.identifier = UUID.UUID();
    this.activity = false;
  }

  private static buildClearOperations(): TextOperation[] {
    return [{ operation: 'clear' }] as TextOperation[];
  }

  private static buildSetOperations(text: string): TextOperation[] {
    return [{ operation: 'clear' }, { operation: 'insert', content: text }] as TextOperation[];
  }

  private static createConfiguration(): ClientConfiguration {
    return {
      textEditorFontSize: ClientConfigurationDefaults.DEFAULT_TEXT_EDITOR_FONT_SIZE,
      subtitleTextEditorFontSize: ClientConfigurationDefaults.DEFAULT_SUBTITLE_TEXT_EDITOR_FONT_SIZE,
      subtitleRowCount: ClientConfigurationDefaults.DEFAULT_SUBTITLE_ROW_COUNT,
      printMargin: ClientConfigurationDefaults.DEFAULT_PRINT_MARGIN,
      wrap: ClientConfigurationDefaults.DEFAULT_WRAP,
      showPrintMargin: ClientConfigurationDefaults.DEFAULT_SHOW_PRINT_MARGIN,
      showInvisibles: ClientConfigurationDefaults.DEFAULT_SHOW_INVISIBLES,
      subtitleAnimation: ClientConfigurationDefaults.DEFAULT_SUBTITLE_ANIMATION,
      subtitleCharacterDelay: ClientConfigurationDefaults.DEFAULT_SUBTITLE_CHARACTER_DELAY,
      subtitleAsInlineText: ClientConfigurationDefaults.DEFAULT_SUBTITLE_AS_INLINE_TEXT,
      tableScrollHeight: ClientConfigurationDefaults.DEFAULT_TABLE_SCROLL_HEIGHT,
      documentEditor: ClientConfigurationDefaults.DEFAULT_DOCUMENT_EDITOR,
    };
  }

  private static storageConfigurationKey(): string {
    return 'client:configuration';
  }

  private static mergeEditorBindings(editor: Editor) {
    for (const key of Object.keys(editor.commands.commands)) {
      const command = editor.commands.commands[key];

      if (!command.bindKey) {
        continue;
      }

      const nextCommand = { ...command };

      if (nextCommand.name === 'selecttolinestart') {
        nextCommand.bindKey = { win: 'Shift-Home', mac: 'Shift-Home' };
      } else if (nextCommand.name === 'selecttolineend') {
        nextCommand.bindKey = { win: 'Shift-End', mac: 'Shift-End' };
      } else {
        if (typeof command.bindKey !== 'string') {
          nextCommand.bindKey = { win: command.bindKey.win, mac: command.bindKey.win };
        }
      }

      editor.commands.addCommand(nextCommand);
    }
  }

  private static getConditionalTrailingLineFeed(session: EditSession): string {
    return ClientPageComponent.isTrailingLineFeedRequired(session) ? '\n' : '';
  }

  private static isTrailingLineFeedRequired(session: EditSession): boolean {
    const lastLine = session.getLine(session.getLength() - 1);

    if (lastLine.length === 0 || lastLine.endsWith('\n')) {
      return false;
    }

    return true;
  }

  private static isCursorAtEnd(editor: Editor): boolean {
    const session = editor.getSession();
    const cursorPosition = editor.getCursorPosition();
    return cursorPosition.row === session.getLength() - 1 && cursorPosition.column === session.getLine(session.getLength() - 1).length;
  }

  private static insertCurrentTimestamp(editor: Editor): void {
    const date = new Date();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const timestamp = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    editor.insert(timestamp + ' ');
  }

  ngOnInit(): void {
    this.subtitleInProgress = false;

    this.speech = '';

    const localStorageConfiguration = this.loadClientConfiguration();

    if (localStorageConfiguration) {
      this.configuration = localStorageConfiguration;
    } else {
      this.configuration = ClientPageComponent.createConfiguration();
    }

    this.selectedSubtitleProxy = null;
    this.currentSubtitleProxy = null;

    this.menuBarItems = [
      {
        label: 'File',
        items: [
          { label: 'New', icon: 'pi pi-fw pi-plus', command: () => this.onMenuBarNew() },
          { label: 'Rename', icon: 'pi pi-fw pi-pencil', command: () => this.onMenuBarRename() },
          { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => this.onMenuBarDelete() },
          { separator: true },
          { label: 'Settings', icon: 'pi pi-fw pi-cog', command: () => this.onMenuBarSettings() },
          { separator: true },
          { label: 'Clock', icon: 'pi pi-fw pi-clock', command: () => this.toggleTimerLoop() },
          { separator: true },
          { label: 'Reset archive', icon: 'pi pi-fw pi-times', command: () => this.onMenuBarResetArchive() },
          { separator: true },
          { label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: () => this.onMenuBarLogout() },
        ],
      },
    ];

    this.apiService.retrieveCurrentTextSegment().subscribe((value) => {
      this.withDisableTextEditorChangeEvents(() => this.getAceTextEditor().getSession().setValue(value));

      this.messageService.add({
        severity: 'success',
        summary: 'Text segment loaded',
        detail: `${value.length} characters`,
      });

      this.deferredEditorMoveCursorToEnd(this.getAceTextEditor());
      this.createWebSocket();
    });

    this.observeLoadDocumentProxyList().subscribe();

    this.subtitleProxies = [];

    this.dialogTitleText = '';

    this.resizeObservable$ = fromEvent<Event>(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.postResizeAceEditors();
    });
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }

    if (this.speechSafeguardSubscription$) {
      this.speechSafeguardSubscription$.unsubscribe();
    }

    if (this.currentTextSegmentStoreSubscription$) {
      this.currentTextSegmentStoreSubscription$.unsubscribe();
    }

    if (this.subtitleTextUpdateSubscription$) {
      this.subtitleTextUpdateSubscription$.unsubscribe();
    }

    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }

    if (this.unlockingSubscription$) {
      this.unlockingSubscription$.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.textEditor = ace.edit(this.textEditorElement.nativeElement);
    this.subtitleTextEditor = ace.edit(this.subtitleTextEditorElement.nativeElement);

    this.textEditorElement.nativeElement.addEventListener('keydown', (e) => {
      this.activity = true;
      this.sendActivityMessage();
    });

    this.textEditorElement.nativeElement.addEventListener('click', (e) => {
      this.activity = true;
      this.sendActivityMessage();
    });

    this.updateSubtitleTextEditorOptions();
    this.updateSubtitleTextEditorBindings();

    this.updateTextEditorOptions();
    this.updateTextEditorBindings();

    this.postResizeAceEditors();

    this.getAceTextEditor().getSession().setValue('');
    this.getAceSubtitleTextEditor().getSession().setValue('');
    this.getAceSubtitleTextEditor().setReadOnly(true);

    this.getAceTextEditor().on('change', (delta) => {
      if (this.disableTextEditorChangeEvents) {
        return;
      }

      this.postHandleAceTextEditorChangeEvent(delta);
    });

    this.getAceSubtitleTextEditor().on('change', (delta) => {
      if (this.disableSubtitleTextEditorChangeEvents) {
        return;
      }

      this.postHandleAceSubtitleTextEditorChangeEvent(delta);
    });
  }

  onDocumentProxyChanged(subtitleProxy: SubtitleProxy): void {
    if (this.subtitleTextUpdateSubscription$) {
      this.subtitleTextUpdateSubscription$.unsubscribe();
    }

    this.selectedSubtitleProxy = subtitleProxy;

    const loadDocument = () => {
      if (!subtitleProxy) {
        this.getAceSubtitleTextEditor().getSession().setValue('');
        this.getAceSubtitleTextEditor().setReadOnly(true);
        this.currentSubtitleProxy = null;

        this.postResizeAceEditors();
        return;
      }

      this.apiService.readDocument(subtitleProxy.identifier).subscribe((document) => {
        this.getAceSubtitleTextEditor().getSession().setValue(document.text);
        this.getAceSubtitleTextEditor().setReadOnly(false);
        this.currentSubtitleProxy = subtitleProxy;
        this.getAceSubtitleTextEditor().gotoLine(0, 0, false);
        this.postResizeAceEditors();
      });
    };

    if (this.currentSubtitleProxy) {
      this.apiService
        .updateDocument(
          this.currentSubtitleProxy.identifier,
          this.currentSubtitleProxy.title,
          this.getAceSubtitleTextEditor().getSession().getValue(),
        )
        .subscribe(() => loadDocument());
    } else {
      loadDocument();
    }
  }

  onDocumentProxyReorder(): void {
    this.apiService.setDocumentOrder(this.subtitleProxies.map((value) => value.identifier)).subscribe();
  }

  onNewFile(): void {
    if (this.subtitleTextUpdateSubscription$) {
      this.subtitleTextUpdateSubscription$.unsubscribe();
    }

    this.isNewDialogVisible = false;

    this.apiService.createDocument(this.dialogTitleText, '').subscribe((identifier) => {
      this.observeLoadDocumentProxyList().subscribe(() => {
        this.selectedSubtitleProxy = this.subtitleProxies.filter((x) => x.identifier === identifier)[0];

        this.apiService.readDocument(identifier).subscribe((document) => {
          this.currentSubtitleProxy = this.selectedSubtitleProxy;
          this.getAceSubtitleTextEditor().getSession().setValue(document.text);
          this.getAceSubtitleTextEditor().setReadOnly(false);
          this.postResizeAceEditors();
        });
      });
    });
  }

  observeLoadDocumentProxyList(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.apiService.getDocumentProxyList().subscribe((value) => {
        this.subtitleProxies = value;

        subscriber.next();
      });
    });
  }

  onRenameFile(): void {
    if (!this.currentSubtitleProxy) {
      return;
    }

    if (this.subtitleTextUpdateSubscription$) {
      this.subtitleTextUpdateSubscription$.unsubscribe();
    }

    this.subtitleTextUpdateSubscription$ = timer(this.AUTO_SAVE_INTERVAL).subscribe(() => {
      this.apiService
        .updateDocument(this.currentSubtitleProxy.identifier, this.dialogTitleText, this.getAceSubtitleTextEditor().getSession().getValue())
        .subscribe(() => {
          this.isRenameDialogVisible = false;
          this.selectedSubtitleProxy.title = this.dialogTitleText;
          this.subtitleProxies = [...this.subtitleProxies];
          this.dialogTitleText = '';
        });
    });
  }

  validDocumentTitleText(): boolean {
    return this.dialogTitleText.trim().length !== 0;
  }

  onSubtitleEditorKeyDown(event: KeyboardEvent): void {
    if (event.key === 'F9') {
      event.preventDefault();
      this.handleClearSubtitle();
    }

    if (event.key === 'F10') {
      event.preventDefault();
      this.handleSendSubtitle();
    }
  }

  resetSettings(): void {
    this.configuration = ClientPageComponent.createConfiguration();
  }

  dismissSettings(): void {
    this.isSettingsDialogVisible = false;
    this.updateSubtitleTextEditorOptions();
    this.storeClientConfiguration(this.configuration);
  }

  filterBySearchInput(): void {
    this.table.filterGlobal(this.search, 'contains');
  }

  clearSearchInput(): void {
    this.search = '';
    this.filterBySearchInput();
  }

  onSubtitleTextEditorOptionsChanged(): void {
    this.onConfigurationChange();

    timer(0).subscribe(() => {
      this.updateSubtitleTextEditorOptions();
    });
  }

  onTableScrollHeightChanged(): void {
    this.postResizeAceEditors();
  }

  getSpeechLabel(): string {
    return this.speech.length > 0 ? this.speech : '...';
  }

  onConfigurationChange(): void {
    timer(0).subscribe(() => {
      this.storeClientConfiguration(this.configuration);
    });
  }

  private startTimerLoop(): void {
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }

    this.timerSubscription$ = this.timestampLoop();
  }

  private stopTimerLoop(): void {
    this.timerSubscription$.unsubscribe();
    this.timerSubscription$ = null;
  }

  private toggleTimerLoop(): void {
    if (this.timerSubscription$) {
      this.stopTimerLoop();
    } else {
      this.startTimerLoop();
    }
  }

  private timestampLoop(): Subscription {
    return timer(this.TIMESTAMP_INTERVAL).subscribe(() => {
      if (this.getAceTextEditor()) {
        ClientPageComponent.insertCurrentTimestamp(this.getAceTextEditor());
      }

      this.timerSubscription$ = this.timestampLoop();
    });
  }

  private postHandleAceTextEditorChangeEvent(delta: Delta): void {
    timer(0).subscribe(() => this.handleAceTextEditorChangeEvent(delta));
  }

  private postHandleAceSubtitleTextEditorChangeEvent(delta: Delta): void {
    timer(0).subscribe(() => this.handleAceSubtitleTextEditorChangeEvent(delta));
  }

  private handleAceTextEditorChangeEvent(delta: Delta): void {
    if (this.unlockingSubscription$) {
      return;
    }

    const editor = this.getAceTextEditor();
    const session = editor.getSession();
    const document = session.getDocument();

    const sessionText = session.getValue();

    const deltaStart = document.positionToIndex(delta.start);
    const deltaText = delta.lines.join('\n');

    if (sessionText.length === 0 && deltaText.length > 1) {
      this.archiveTextSegment(deltaText);
      this.scheduleStoreCurrentTextSegment();
      this.sendTextUpdateMessage(sessionText);
      return;
    }

    switch (delta.action) {
      case 'insert': {
        const operations: TextOperation[] = [
          { operation: 'skip', count: deltaStart },
          { operation: 'insert', content: deltaText },
        ];

        this.sendTextUpdateMessage(sessionText);
        break;
      }

      case 'remove': {
        const operations: TextOperation[] = [
          { operation: 'skip', count: deltaStart },
          { operation: 'remove', count: deltaText.length },
        ];

        this.sendTextUpdateMessage(sessionText);
        break;
      }

      default:
        break;
    }

    this.maybeSplitCurrentLine('. ', () => {
      editor.remove('left');
      editor.insert('\n');
    });

    this.postMaybeArchiveTextSegment();
    this.scheduleStoreCurrentTextSegment();
  }

  private handleAceSubtitleTextEditorChangeEvent(delta: Delta): void {
    // if (!this.currentSubtitleProxy) {
    // }

    if (this.subtitleTextUpdateSubscription$) {
      this.subtitleTextUpdateSubscription$.unsubscribe();
    }

    this.subtitleTextUpdateSubscription$ = timer(this.AUTO_SAVE_INTERVAL).subscribe(() => {
      if (!this.currentSubtitleProxy) {
        return;
      }

      this.apiService
        .updateDocument(
          this.currentSubtitleProxy.identifier,
          this.currentSubtitleProxy.title,
          this.getAceSubtitleTextEditor().getSession().getValue(),
        )
        .subscribe();
    });
  }

  private postResizeAceEditors() {
    timer(0).subscribe(() => {
      this.getAceTextEditor().setOptions({ wrap: true });

      this.getAceSubtitleTextEditor().resize();
      this.getAceSubtitleTextEditor().renderer.updateFull();

      this.getAceTextEditor().resize();
      this.getAceTextEditor().renderer.updateFull();
    });
  }

  private updateTextEditorOptions(): void {
    // https://ace.c9.io/build/kitchen-sink.html
    // https://github.com/ajaxorg/ace/wiki/Configuring-Ace
    // https://blog.shhdharmen.me/how-to-setup-ace-editor-in-angular

    this.getAceTextEditor().setOptions({
      mode: 'ace/mode/text',
      highlightActiveLine: false,
      indentedSoftWrap: false,
      showLineNumbers: false,
      showGutter: false,
      printMargin: 0,
      wrap: true,
      showPrintMargin: false,
      showInvisibles: false,
    });

    this.getAceTextEditor().setTheme('ace/theme/terminal');
  }

  private updateSubtitleTextEditorOptions(): void {
    // https://ace.c9.io/build/kitchen-sink.html
    // https://github.com/ajaxorg/ace/wiki/Configuring-Ace
    // https://blog.shhdharmen.me/how-to-setup-ace-editor-in-angular

    this.getAceSubtitleTextEditor().setOptions({
      mode: 'ace/mode/text',
      highlightActiveLine: false,
      indentedSoftWrap: false,
      showLineNumbers: false,
      showGutter: false,
      printMargin: this.configuration.printMargin,
      wrap: this.configuration.wrap,
      showPrintMargin: this.configuration.showPrintMargin,
      showInvisibles: this.configuration.showInvisibles,
    });

    this.getAceSubtitleTextEditor().setTheme('ace/theme/terminal');
  }

  private updateSubtitleTextEditorBindings(): void {
    // https://github.com/ajaxorg/ace/blob/master/lib/ace/commands/default_commands.js#L366

    ClientPageComponent.mergeEditorBindings(this.getAceSubtitleTextEditor());

    // this.getAceSubtitleTextEditor().commands.addCommand({
    //   name: 'command_name',
    //   bindKey: { win: '', mac: '' },
    //   exec: (editor) => {
    //     this.getAceSubtitleTextEditor().commands.exec('gotowordleft', this.getAceSubtitleTextEditor());
    //   },
    // });
  }

  private updateTextEditorBindings(): void {
    ClientPageComponent.mergeEditorBindings(this.getAceTextEditor());
  }

  private handleClearSubtitle(): void {
    this.clearSubtitle();
    this.scheduleRefocus();
  }

  private handleSendSubtitle(): void {
    this.emitSubtitle();
    this.scheduleRefocus();
  }

  private scheduleRefocus(): void {
    timer(5).subscribe(() => {
      this.getAceSubtitleTextEditor().blur();
    });
    timer(10).subscribe(() => {
      this.getAceTextEditor().focus();
    });
    timer(15).subscribe(() => {
      this.getAceTextEditor().blur();
    });
    timer(20).subscribe(() => {
      this.getAceSubtitleTextEditor().focus();
    });
  }

  private deferredEditorMoveCursorToEnd(editor: Editor): void {
    timer(0).subscribe(() => {
      const session = editor.getSession();

      editor.clearSelection();
      editor.moveCursorTo(session.getLength(), 0);
      this.deferredEditorScrollToCursor(editor);
    });
  }

  private deferredEditorScrollToCursor(editor: Editor): void {
    timer(0).subscribe(() => {
      editor.renderer.scrollCursorIntoView(editor.getCursorPosition());
    });
  }

  private createWebSocket(): void {
    console.log(`createWebSocket: ${this.buildWebSocketUrl()}`);

    this.webSocket = new WebSocket(this.buildWebSocketUrl());

    this.webSocket.onopen = (ev) => {
      console.log(`webSocket.onopen: ${JSON.stringify(ev)}`);
      this.messageService.add({ severity: 'success', summary: 'Network connected' });
    };

    this.webSocket.onerror = (ev) => {
      console.log(`webSocket.onerror: ${JSON.stringify(ev)}`);
      this.messageService.add({ severity: 'error', summary: 'Network error' });
    };

    this.webSocket.onclose = (ev) => {
      console.log(`webSocket.onclose: ${JSON.stringify(ev)}`);
      this.messageService.add({ severity: 'warn', summary: 'Network disconnected' });

      timer(WEBSOCKET_RECONNECT_DELAY).subscribe(() => {
        this.createWebSocket();
      });
    };

    this.webSocket.onmessage = (ev) => {
      const websocketMessage = JSON.parse(ev.data) as WebSocketMessage;

      this.handleWebSocketMessage(websocketMessage);
    };
  }

  private handleWebSocketMessage(message: WebSocketMessage): void {
    switch (message.message_type) {
      case 'speech_transcript': {
        this.handleSpeechTranscriptMessage(message.message_data as WebSocketMessageDataSpeechTranscript);
        break;
      }

      case 'text_update': {
        this.handleTextUpdateMessage(message.message_data as WebSocketMessageDataTextUpdate);
        break;
      }

      case 'activity': {
        this.handleActivityMessage(message.message_data as WebSocketMessageDataActivity);
        break;
      }

      default:
        break;
    }
  }

  private handleSpeechTranscriptMessage(message: WebSocketMessageDataSpeechTranscript): void {
    if (!this.activity) {
      return;
    }

    if (message.final) {
      const editor = this.getAceTextEditor();
      const session = editor.getSession();

      const editorScrollRequired = ClientPageComponent.isCursorAtEnd(editor);

      const range = editor.getSelection().getRange();

      this.speech = '';

      session.insert(
        { row: session.getLength(), column: 0 },
        (TextTools.isGlueRequired(this.getAceTextEditor().getSession().getValue(), message.text) ? '' : ' ') + message.text,
      );

      this.maybeSplitCurrentLine('.', () => {
        editor.insert('\n');
      });

      if (editorScrollRequired) {
        this.deferredEditorScrollToCursor(editor);
      } else {
        timer(0).subscribe(() => {
          editor.getSelection().setRange(range);
        });
      }
    } else {
      this.speech = message.text;
      this.scheduleSpeechSafeguard();
    }
  }

  private handleTextUpdateMessage(message: WebSocketMessageDataTextUpdate) {
    if (message.identifier == this.identifier) {
      return;
    }

    this.withDisableTextEditorChangeEvents(() => {
      this.getAceTextEditor().getSession().setValue(message.text);
    });

    this.handlePostTextChange();
  }

  private handlePostTextChange() {
    const row = this.getAceTextEditor().session.getLength() - 1;
    const column = this.getAceTextEditor().session.getLine(row).length;

    this.getAceTextEditor().moveCursorToPosition({ row, column });

    this.deferredEditorScrollToCursor(this.getAceTextEditor());

    if (this.unlockingSubscription$ === null) {
      console.log('Locking editor');

      this.getAceTextEditor().setOptions({
        readOnly: true,
      });
    } else {
      this.unlockingSubscription$.unsubscribe();
      this.unlockingSubscription$ = null;
    }

    this.unlockingSubscription$ = timer(this.UNLOCKING_INTERVAL).subscribe((value) => {
      this.unlockingSubscription$.unsubscribe();
      this.unlockingSubscription$ = null;

      console.log('Unlocking editor');

      this.getAceTextEditor().setOptions({
        readOnly: false,
      });

      this.deferredEditorScrollToCursor(this.getAceTextEditor());
    });
  }

  private handleActivityMessage(messageData: WebSocketMessageDataActivity) {
    if (messageData.identifier != this.identifier) {
      this.activity = false;
      this.speech = '';
    }
  }

  private buildWebSocketUrl(): string {
    const protocol = this.window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    return protocol + this.window.location.host + `${this.baseHref}api/websocket`;
  }

  private sendTextUpdateMessage(text: string): void {
    if (this.webSocket.readyState !== WebSocket.OPEN) {
      return;
    }

    const message: WebSocketMessage = {
      message_type: 'text_update',
      message_data: {
        identifier: this.identifier,
        text: text,
      },
    };

    this.webSocket.send(JSON.stringify(message));
  }

  private sendReloadMessage(): void {
    if (this.webSocket.readyState !== WebSocket.OPEN) {
      return;
    }

    const message: WebSocketMessage = {
      message_type: 'reload',
      message_data: {
        identifier: this.identifier,
      },
    };

    this.webSocket.send(JSON.stringify(message));
  }

  private sendActivityMessage(): void {
    if (this.webSocket.readyState !== WebSocket.OPEN) {
      return;
    }

    const message: WebSocketMessage = {
      message_type: 'activity',
      message_data: {
        identifier: this.identifier,
      },
    };

    this.webSocket.send(JSON.stringify(message));
  }

  private scheduleStoreCurrentTextSegment(): void {
    if (this.currentTextSegmentStoreSubscription$) {
      this.currentTextSegmentStoreSubscription$.unsubscribe();
    }

    this.currentTextSegmentStoreSubscription$ = timer(this.AUTO_SAVE_INTERVAL).subscribe(() => {
      this.apiService.storeCurrentTextSegment(this.getAceTextEditor().getSession().getValue()).subscribe((value) => {
        this.sendReloadMessage();
      });
    });
  }

  private archiveTextSegment(text: string): void {
    this.apiService.archiveTextSegment(text).subscribe((value) => {
      this.sendReloadMessage();
    });
  }

  private maybeSplitCurrentLine(value: string, callback: () => void): void {
    const editor = this.getAceTextEditor();
    const session = editor.getSession();

    const cursorPosition = editor.getCursorPosition();

    const line = session.getLine(cursorPosition.row);

    if (line.length >= this.LINE_LENGTH_THRESHOLD && cursorPosition.column === line.length && line.endsWith(value)) {
      callback();
      this.deferredEditorMoveCursorToEnd(editor);
    }
  }

  private postMaybeArchiveTextSegment(): void {
    timer(0).subscribe(() => this.maybeArchiveTextSegment());
  }

  private maybeArchiveTextSegment(): void {
    const editor = this.getAceTextEditor();
    const session = editor.getSession();

    const sessionText = session.getValue();

    if (sessionText.length < this.TEXT_LENGTH_THRESHOLD_TRIGGER) {
      return;
    }

    let targetPayloadSize = 0;
    let targetLine = -1;

    while (targetLine < session.getLength() - 1 && sessionText.length - targetPayloadSize >= this.TEXT_LENGTH_THRESHOLD_HIGH) {
      const nextTargetLineText = session.getLine(targetLine + 1);

      // account for trailing '\n'
      const nextTargetPayloadSize = targetPayloadSize + nextTargetLineText.length + 1;

      if (sessionText.length - nextTargetPayloadSize < this.TEXT_LENGTH_THRESHOLD_LOW) {
        break;
      }

      targetLine++;
      targetPayloadSize = nextTargetPayloadSize;
    }

    if (targetLine < 0) {
      return;
    }

    const lines = session.getLines(0, targetLine);

    // account for the last line
    const removedText = lines.join('\n') + '\n';

    this.apiService.archiveTextSegment(removedText).subscribe(() => {
      this.sendReloadMessage();

      this.messageService.add({
        severity: 'success',
        summary: 'Text segment archived',
        detail: `${removedText.length} characters`,
      });
    });

    session.removeFullLines(0, targetLine);
  }

  private onMenuBarNew(): void {
    this.dialogTitleText = '';
    this.isNewDialogVisible = true;
  }

  private onMenuBarRename(): void {
    if (!this.selectedSubtitleProxy) {
      return;
    }

    this.dialogTitleText = this.selectedSubtitleProxy.title;
    this.isRenameDialogVisible = true;
  }

  private onMenuBarDelete(): void {
    if (!this.selectedSubtitleProxy) {
      return;
    }

    this.confirmationService.confirm({
      message: 'Delete file?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-times',
      icon: 'pi pi-trash',
      accept: () => {
        if (this.subtitleTextUpdateSubscription$) {
          this.subtitleTextUpdateSubscription$.unsubscribe();
        }

        this.apiService.deleteDocument(this.selectedSubtitleProxy.identifier).subscribe((value) => {
          if (value) {
            this.subtitleProxies = this.subtitleProxies.filter((proxy) => proxy.identifier !== this.selectedSubtitleProxy.identifier);

            this.getAceSubtitleTextEditor().getSession().setValue('');
            this.getAceSubtitleTextEditor().setReadOnly(true);

            this.postResizeAceEditors();

            this.currentSubtitleProxy = null;
            this.selectedSubtitleProxy = null;
          }
        });
      },
    });
  }

  private onMenuBarSettings(): void {
    this.isSettingsDialogVisible = true;
  }

  private onMenuBarResetArchive(): void {
    this.confirmationService.confirm({
      message: 'Reset session?',
      acceptLabel: 'Reset',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-times',
      icon: 'pi pi-trash',
      accept: () => {
        this.apiService.deleteArchive().subscribe((value) => {
          this.sendReloadMessage();

          this.messageService.add({
            severity: 'success',
            summary: 'Session reset',
          });
        });
      },
    });
  }

  private onMenuBarLogout(): void {
    this.confirmationService.confirm({
      message: 'Logout?',
      acceptLabel: 'Logout',
      acceptIcon: 'pi pi-sign-out',
      acceptButtonStyleClass: 'p-button-success',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-times',
      icon: 'pi pi-sign-out',
      accept: () => {
        this.authService.removeToken();
        this.router.navigate(['/auth/login']).then();
      },
    });
  }

  private emitSubtitle(): void {
    if (this.subtitleInProgress) {
      return;
    }

    const editor = this.getAceSubtitleTextEditor();
    const session = editor.getSession();

    const cursor = editor.selection.getCursor();

    let firstLine = cursor.row;
    let lastLine = cursor.row;

    while (firstLine < session.getLength() - 1 && session.getLine(firstLine).length === 0) {
      firstLine++;
    }

    if (session.getLine(firstLine).length === 0) {
      return;
    }

    while (firstLine > 0 && session.getLine(firstLine - 1).length > 0) {
      firstLine--;
    }

    while (lastLine < session.getLength() - 1 && session.getLine(lastLine + 1).length > 0) {
      lastLine++;
    }

    let nextFirstLine = lastLine + 1;

    while (nextFirstLine < session.getLength() - 1 && session.getLine(nextFirstLine).length === 0) {
      nextFirstLine++;
    }

    editor.selection.moveCursorTo(nextFirstLine, 0, false);
    editor.selection.clearSelection();

    this.deferredEditorScrollToCursor(editor);

    const subtitle = session.getLines(firstLine, lastLine);

    if (this.configuration.subtitleAnimation) {
      void this.emitSubtitleAsync(subtitle);
    } else {
      this.emitSubtitleSync(subtitle);
    }
  }

  private getAceTextEditor(): Editor {
    return this.textEditor;
  }

  private getAceSubtitleTextEditor(): Editor {
    return this.subtitleTextEditor;
  }

  private emitSubtitleSync(subtitle: string[]): void {
    const editor = this.getAceTextEditor();
    const session = editor.getSession();

    if (this.configuration.subtitleAsInlineText) {
      const endRow = session.getLength() - 1;
      const endColumn = session.getLine(endRow).length;
      const conditionalSpace = endColumn > 0 ? ' ' : '';

      session.insert({ row: session.getLength(), column: 0 }, conditionalSpace + subtitle.join(' '));
    } else {
      const lastLineFeed = ClientPageComponent.getConditionalTrailingLineFeed(session);

      const emptyRows = this.getPaddingTextForSubtitle(subtitle.length);

      session.insert({ row: session.getLength(), column: 0 }, lastLineFeed + emptyRows + subtitle.join('\n'));
    }

    this.deferredEditorMoveCursorToEnd(this.getAceTextEditor());
  }

  private async emitSubtitleAsync(subtitle: string[]): Promise<void> {
    this.subtitleInProgress = true;

    try {
      await this.emitSubtitleAsyncSafe(subtitle);
    } finally {
      this.subtitleInProgress = false;
    }
  }

  private async emitSubtitleAsyncSafe(subtitle: string[]): Promise<void> {
    let firstWord = true;

    const editor = this.getAceTextEditor();
    const session = editor.getSession();

    let prefix;

    if (this.configuration.subtitleAsInlineText) {
      prefix = '';
      subtitle = [subtitle.join(' ')];
    } else {
      prefix =
        ClientPageComponent.getConditionalTrailingLineFeed(session) +
        this.getEmptySubtitleText() +
        this.getPaddingTextForSubtitle(subtitle.length);
    }

    for (const subtitleRow of subtitle) {
      const words = subtitleRow.replace(/ +/, ' ').split(' ');

      for (let i = 0; i < words.length; i++) {
        const endRow = session.getLength() - 1;
        const endColumn = session.getLine(endRow).length;

        if (!firstWord) {
          await new Promise((resolve) => setTimeout(resolve, this.configuration.subtitleCharacterDelay * words[i].length));
        } else {
          firstWord = false;
        }

        const conditionalSpace = endColumn > 0 ? ' ' : '';

        session.insert(
          { row: session.getLength(), column: 0 },
          prefix + conditionalSpace + words[i] + (!this.configuration.subtitleAsInlineText && i === words.length - 1 ? '\n' : ''),
        );

        prefix = '';

        this.deferredEditorMoveCursorToEnd(this.getAceTextEditor());
      }
    }
  }

  private getPaddingTextForSubtitle(length: number) {
    return '\n'.repeat(Math.max(0, this.configuration.subtitleRowCount - length));
  }

  private clearSubtitle(): void {
    const editor = this.getAceTextEditor();
    const row = editor.getSession().getLength() - 1;
    const column = editor.getSession().getLine(row).length;

    editor.getSession().insert({ row, column }, this.getEmptySubtitleText());
  }

  private getEmptySubtitleText(): string {
    return '\n'.repeat(this.configuration.subtitleRowCount);
  }

  private storeClientConfiguration(configuration: ClientConfiguration): void {
    this.localStorage.setItem(ClientPageComponent.storageConfigurationKey(), JSON.stringify(configuration));
  }

  private loadClientConfiguration(): ClientConfiguration | null {
    const configurationString = this.localStorage.getItem(ClientPageComponent.storageConfigurationKey());

    if (!configurationString) {
      return null;
    }

    const configuration = JSON.parse(configurationString) as ClientConfiguration;

    if (configuration.textEditorFontSize === undefined) {
      configuration.textEditorFontSize = ClientConfigurationDefaults.DEFAULT_TEXT_EDITOR_FONT_SIZE;
    }

    if (configuration.subtitleTextEditorFontSize === undefined) {
      configuration.subtitleTextEditorFontSize = ClientConfigurationDefaults.DEFAULT_SUBTITLE_TEXT_EDITOR_FONT_SIZE;
    }

    if (configuration.subtitleRowCount === undefined) {
      configuration.subtitleRowCount = ClientConfigurationDefaults.DEFAULT_SUBTITLE_ROW_COUNT;
    }

    if (configuration.printMargin === undefined) {
      configuration.printMargin = ClientConfigurationDefaults.DEFAULT_PRINT_MARGIN;
    }

    if (configuration.wrap === undefined) {
      configuration.wrap = ClientConfigurationDefaults.DEFAULT_WRAP;
    }

    if (configuration.showPrintMargin === undefined) {
      configuration.showPrintMargin = ClientConfigurationDefaults.DEFAULT_SHOW_PRINT_MARGIN;
    }

    if (configuration.showInvisibles === undefined) {
      configuration.showInvisibles = ClientConfigurationDefaults.DEFAULT_SHOW_INVISIBLES;
    }

    if (configuration.subtitleAnimation === undefined) {
      configuration.subtitleAnimation = ClientConfigurationDefaults.DEFAULT_SUBTITLE_ANIMATION;
    }

    if (configuration.subtitleCharacterDelay === undefined) {
      configuration.subtitleCharacterDelay = ClientConfigurationDefaults.DEFAULT_SUBTITLE_CHARACTER_DELAY;
    }

    if (configuration.subtitleAsInlineText === undefined) {
      configuration.subtitleAsInlineText = ClientConfigurationDefaults.DEFAULT_SUBTITLE_AS_INLINE_TEXT;
    }

    if (configuration.tableScrollHeight === undefined) {
      configuration.tableScrollHeight = ClientConfigurationDefaults.DEFAULT_TABLE_SCROLL_HEIGHT;
    }

    if (configuration.documentEditor === undefined) {
      configuration.documentEditor = ClientConfigurationDefaults.DEFAULT_DOCUMENT_EDITOR;
    }

    return configuration;
  }

  private scheduleSpeechSafeguard(): void {
    this.unscheduleSpeechSafeguard();

    this.speechSafeguardSubscription$ = timer(this.SPEECH_SAFEGUARD_DELAY).subscribe(() => {
      this.speech = '';
    });
  }

  private unscheduleSpeechSafeguard(): void {
    if (this.speechSafeguardSubscription$) {
      this.speechSafeguardSubscription$.unsubscribe();
    }
  }

  private withDisableTextEditorChangeEvents(f: () => void) {
    this.disableTextEditorChangeEvents = true;
    try {
      f();
    } finally {
      this.disableTextEditorChangeEvents = false;
    }
  }

  private withDisableSubtitleTextEditorChangeEvents(f: () => void) {
    this.disableSubtitleTextEditorChangeEvents = true;
    try {
      f();
    } finally {
      this.disableSubtitleTextEditorChangeEvents = false;
    }
  }
}
