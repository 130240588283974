export const WEBSOCKET_RECONNECT_DELAY = 5000;

export interface JSendResponse {
  status: 'success' | 'error';
  data: any;
  message?: string;
}

export class ConfigurationDefaults {
  static readonly DEFAULT_POSITION_LEFT = 0;
  static readonly DEFAULT_POSITION_TOP = 0;
  static readonly DEFAULT_POSITION_RIGHT = 0;
  static readonly DEFAULT_POSITION_BOTTOM = 0;
  static readonly DEFAULT_PADDING_LEFT = 0;
  static readonly DEFAULT_PADDING_TOP = 0;
  static readonly DEFAULT_PADDING_RIGHT = 0;
  static readonly DEFAULT_PADDING_BOTTOM = 0;
  static readonly DEFAULT_BACKGROUND_OPACITY = 0;
  static readonly DEFAULT_FONT_FAMILY = 'sans-serif';
  static readonly DEFAULT_FONT_WEIGHT = 'normal';
  static readonly DEFAULT_FONT_SIZE = 28;
  static readonly DEFAULT_LINE_HEIGHT = 1.2;
  static readonly DEFAULT_TEXT_COLOR = '#ffffff';
  static readonly DEFAULT_BACKGROUND_COLOR = '#0000d5';
  static readonly DEFAULT_BORDER_SIZE = 4;
  static readonly DEFAULT_BORDER_COLOR = '#000000';
  static readonly DEFAULT_OUTLINE_COLOR = '#ffffff';
  static readonly DEFAULT_OUTLINE_VISIBLE = false;
  static readonly DEFAULT_OUTLINE_BORDER_SIZE = 2;
  static readonly DEFAULT_LOGO_VISIBLE = false;
  static readonly DEFAULT_LOGO_LEFT = 0;
  static readonly DEFAULT_LOGO_TOP = 0;
  static readonly DEFAULT_LOGO_WIDTH = '100%';
  static readonly DEFAULT_LOGO_HEIGHT = '100%';
}

export interface Configuration {
  positionLeft: number;
  positionTop: number;
  positionRight: number;
  positionBottom: number;
  paddingLeft: number;
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  backgroundOpacity: number;
  fontFamily: string;
  fontWeight: string;
  fontSize: number;
  lineHeight: number;
  textColor: string;
  backgroundColor: string;
  borderSize: number;
  borderColor: string;
  outlineColor: string;
  outlineVisible: boolean;
  outlineBorderSize: number;
  logoVisible: boolean;
  logoLeft: number;
  logoTop: number;
  logoWidth: string;
  logoHeight: string;
}

export class TextTools {
  private static readonly LEFT_CONNECTORS = ['(', '[', '{'];
  private static readonly RIGHT_CONNECTORS = [')', ']', '}', '!', '%', ',', '.', ':', ';', '?'];

  static isGlueRequired(leftText: string, rightText: string): boolean {
    return TextTools.isEolGlueRequired(leftText) || TextTools.isLeftGlueRequired(leftText) || TextTools.isRightGlueRequired(rightText);
  }

  private static isEolGlueRequired(text: string): boolean {
    const textIsEmpty = text.length === 0;
    const lastLineIsEmpty = text.length > 0 && text[text.length - 1] === '\n';

    return textIsEmpty || lastLineIsEmpty;
  }

  private static isLeftGlueRequired(text: string): boolean {
    return text.length > 0 && this.LEFT_CONNECTORS.includes(text[text.length - 1]);
  }

  private static isRightGlueRequired(text: string): boolean {
    return text.length > 0 && this.RIGHT_CONNECTORS.includes(text[0]);
  }
}

export type OutputIdentifier = 'main' | 'fill' | 'key';

export type ClearTextOperation = { operation: 'clear' };
export type InsertTextOperation = { operation: 'insert'; content: string };
export type RemoveTextOperation = { operation: 'remove'; count: number };
export type SkipTextOperation = { operation: 'skip'; count: number };

export type TextOperation = ClearTextOperation | InsertTextOperation | RemoveTextOperation | SkipTextOperation;

export type WebSocketTypeConfiguration = 'configuration';
export type WebSocketTypeTextUpdate = 'text_update';
export type WebSocketTypeSpeechTranscript = 'speech_transcript';
export type WebSocketTypeReload = 'reload';
export type WebSocketTypeActivity = 'activity';
export type WebSocketTypeTextOverride = 'text_override';

export type WebSocketMessageType =
  | WebSocketTypeConfiguration
  | WebSocketTypeTextUpdate
  | WebSocketTypeSpeechTranscript
  | WebSocketTypeReload
  | WebSocketTypeActivity
  | WebSocketTypeTextOverride;

export interface WebSocketMessageDataConfiguration {
  site_identifier: string;
  configuration: Configuration;
}

export interface WebSocketMessageDataTextUpdate {
  identifier: string;
  text: string;
}

export interface WebSocketMessageDataDeltaTextUpdate {
  identifier: string;
  text_operations: TextOperation[];
  speech: string;
}

export interface WebSocketMessageDataSpeechTranscript {
  text: string;
  final: boolean;
}

export interface WebSocketMessageDataReload {
  identifier: string;
}

export interface WebSocketMessageDataActivity {
  identifier: string;
}

export interface WebSocketMessageDataTextOverride {
  site_identifier: string;
  text_override: string;
}

export interface WebSocketMessage {
  message_type: WebSocketMessageType;
  message_data?:
    | WebSocketMessageDataConfiguration
    | WebSocketMessageDataTextUpdate
    | WebSocketMessageDataDeltaTextUpdate
    | WebSocketMessageDataSpeechTranscript
    | WebSocketMessageDataReload
    | WebSocketMessageDataActivity
    | WebSocketMessageDataTextOverride;
}

export function processDelta(value: string, operations: TextOperation[]): string {
  let index = 0;

  operations.forEach((textOperation) => {
    switch (textOperation.operation) {
      case 'clear': {
        value = '';
        index = 0;
        break;
      }

      case 'insert': {
        value = value.slice(0, index) + textOperation.content + value.slice(index);
        index += textOperation.content.length;
        break;
      }

      case 'remove': {
        value = value.slice(0, index) + value.slice(index + textOperation.count);
        break;
      }

      case 'skip': {
        index += textOperation.count;
        break;
      }
    }
  });

  return value;
}

export interface DropDownOption {
  value: string;
  label: string;
}

export interface Style {
  [key: string]: string | number;
}

// https://awik.io/determine-color-bright-dark-using-javascript/
export function lightOrDark(color: string): 'light' | 'dark' {
  const parseRGB = (color: string): [number, number, number] => {
    const matches = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    return matches ? [+matches[1], +matches[2], +matches[3]] : [0, 0, 0];
  };

  const parseHEX = (color: string): [number, number, number] => {
    const hexColorValue = parseInt(color.slice(1), 16);
    const r = hexColorValue >> 16;
    const g = (hexColorValue >> 8) & 255;
    const b = hexColorValue & 255;
    return [r, g, b];
  };

  let r;
  let g;
  let b;

  if (color.match(/^rgb|^#/i)) {
    if (color.startsWith('rgb')) {
      [r, g, b] = parseRGB(color);
    } else {
      [r, g, b] = parseHEX(color);
    }
  } else {
    [r, g, b] = [0, 0, 0];
  }

  const hsp = Math.sqrt(0.299 * r ** 2 + 0.587 * g ** 2 + 0.114 * b ** 2);

  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
}
