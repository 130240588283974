<div class="flex align-items-center justify-content-between px-3 livesub-navigation">
  <h1 style="font-family: sans-serif">LiveSub</h1>

  <div>
    <a routerLink="/auth/login"><i class="pi pi-sign-out" pTooltip="Sign out" style="font-size: 1.5em"></i></a>
  </div>
</div>

<div class="flex m-0 flex-row">
  <div class="flex flex-column livesub-main">
    <div class="livesub-main-content p-0">
      <router-outlet></router-outlet>
    </div>
    <div class="flex justify-content-center align-items-center livesub-footer">
      <p style="font-family: sans-serif">&copy; 2020-2023 Universal Multimedia Access Srl</p>
    </div>
  </div>
</div>
