import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { Subscription, timer } from 'rxjs';

type SwlMessageData = {
  swl: number;
};

type SwlMessageObject = {
  type: 'swl';
  data: SwlMessageData;
};

@Component({
  selector: 'app-feed-page',
  templateUrl: './feed-page.component.html',
  styleUrls: ['./feed-page.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonModule, DropdownModule, FormsModule],
})
export class FeedPageComponent implements OnInit, OnDestroy {
  private static readonly TIME_SLICE = 500;
  private static readonly SWL_UPDATE_INTERVAL = 250;
  private static readonly SAMPLE_RATE = 48000;

  rawAudioSwl = 0;
  devices: MediaDeviceInfo[];
  defaultDeviceId: string;
  deviceId: string;

  private internalRawAudioSwl = 0;
  private mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
  private swlProcessor: AudioWorkletNode;
  private micProcessor: AudioWorkletNode;
  private audioContext: AudioContext;
  private mediaRecorder: MediaRecorder;
  private rawAudioWebSocket: WebSocket;
  private containerAudioWebSocket: WebSocket;
  private swlTimerSubscription$: Subscription;

  constructor(@Inject(APP_BASE_HREF) private baseHref: string) {}

  ngOnInit(): void {
    this.swlTimerSubscription$ = timer(0, FeedPageComponent.SWL_UPDATE_INTERVAL).subscribe(() => {
      this.rawAudioSwl = this.internalRawAudioSwl;
    });

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.devices = devices;

      navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
        const audioTrack = stream.getAudioTracks()[0];
        const settings = audioTrack.getSettings();
        this.defaultDeviceId = settings.deviceId;
        this.deviceId = null;
      });
    });
  }

  ngOnDestroy(): void {
    if (this.swlTimerSubscription$) {
      this.swlTimerSubscription$.unsubscribe();
    }
  }

  async addAudioWorklets(audioContext: AudioContext): Promise<void> {
    await audioContext.audioWorklet.addModule('assets/audio-processor-swl.js');
    await audioContext.audioWorklet.addModule('assets/audio-processor-mic.js');
  }

  onStartRawTranscription() {
    this.startRawTranscription().then((r) => console.log('startRawTranscription', r));
  }

  onStopRawTranscription() {
    this.stopRawTranscription().then((r) => {
      console.log('stopRawTranscription', r);

      timer(FeedPageComponent.SWL_UPDATE_INTERVAL).subscribe(() => {
        this.internalRawAudioSwl = 0;
      });
    });
  }

  isRawAudioTranscriptionRunning(): boolean {
    return this.rawAudioWebSocket && this.rawAudioWebSocket.readyState === WebSocket.OPEN;
  }

  private async startRawTranscription(): Promise<void> {
    if (!navigator.mediaDevices) {
      return;
    }

    const webSocketPath = `${this.baseHref}api/transcribe-raw-audio`;
    const webSocketProtocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
    const webSocketUrl = `${webSocketProtocol}//${location.host}${webSocketPath}`;

    try {
      console.log(`Recording from device ${this.deviceId}`);

      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.defaultDeviceId } });

      this.rawAudioWebSocket = new WebSocket(webSocketUrl);

      this.rawAudioWebSocket.addEventListener('open', () => {
        console.log('WebSocket connection open (raw audio)');
      });

      this.rawAudioWebSocket.addEventListener('close', () => {
        console.log('WebSocket connection closed (raw audio)');
        this.stopRawTranscription();
      });

      await this.initAudioProcessing(mediaStream);
    } catch (e) {
      console.error('Failed to start raw transcription', e);
    }
  }

  private async initAudioProcessing(stream: MediaStream): Promise<void> {
    this.audioContext = new AudioContext({
      sampleRate: FeedPageComponent.SAMPLE_RATE,
      latencyHint: 'interactive',
    });

    await this.addAudioWorklets(this.audioContext);

    this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(stream);

    this.swlProcessor = new AudioWorkletNode(this.audioContext, 'audio-processor-swl');
    this.mediaStreamAudioSourceNode.connect(this.swlProcessor);

    this.micProcessor = new AudioWorkletNode(this.audioContext, 'audio-processor-mic');
    this.mediaStreamAudioSourceNode.connect(this.micProcessor);

    await this.audioContext.resume();

    this.swlProcessor.port.onmessage = (event) => {
      const swlMessageObject = event.data as SwlMessageObject;
      this.internalRawAudioSwl = swlMessageObject.data.swl;
    };

    this.micProcessor.port.onmessage = (event) => {
      if (!this.rawAudioWebSocket) {
        return;
      }

      if (this.rawAudioWebSocket.readyState === WebSocket.OPEN) {
        this.rawAudioWebSocket.send(event.data.samples);
      } else {
        console.log(`WebSocket is not open: readyState = ${this.rawAudioWebSocket.readyState}`);
      }
    };
  }

  private async stopRawTranscription() {
    if (this.mediaStreamAudioSourceNode) {
      this.mediaStreamAudioSourceNode.disconnect();
      this.mediaStreamAudioSourceNode = null;
    }

    if (this.swlProcessor) {
      this.swlProcessor.disconnect();
      this.swlProcessor = null;
    }

    if (this.micProcessor) {
      this.micProcessor.disconnect();
      this.micProcessor = null;
    }

    if (this.audioContext) {
      await this.audioContext.close();
      this.audioContext = null;
    }

    if (this.rawAudioWebSocket) {
      this.rawAudioWebSocket.close();
      this.rawAudioWebSocket = null;
    }
  }

  private initializeContainerAudio(): void {
    if (!navigator.mediaDevices) {
      return;
    }

    const webSocketPath = `${this.baseHref}/api/container-audio`;
    const webSocketProtocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
    const webSocketUrl = `${webSocketProtocol}//${location.host}${webSocketPath}`;

    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
      this.containerAudioWebSocket = new WebSocket(webSocketUrl);

      this.containerAudioWebSocket.addEventListener('open', () => {
        console.log('WebSocket connection open (container audio)');

        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (e) => {
          if (this.containerAudioWebSocket.readyState === WebSocket.OPEN) {
            this.containerAudioWebSocket.send(e.data);
          } else {
            console.log(`WebSocket is not open: readyState = ${this.containerAudioWebSocket.readyState}`);
          }
        };

        this.mediaRecorder.start(FeedPageComponent.TIME_SLICE);
      });

      this.containerAudioWebSocket.addEventListener('close', () => {
        console.log('WebSocket connection closed (container audio)');

        if (this.mediaRecorder) {
          this.mediaRecorder.stop();
        }
      });
    });
  }
}
