<div class="grid">
  <div class="col-12 md:col-2 md:col-offset-5 d-flex align-items-center">
    <div class="flex flex-column align-items-stretch surface-ground px-3 py-3">
      Transcription

      <p-dropdown
        [(ngModel)]="deviceId"
        [disabled]="isRawAudioTranscriptionRunning()"
        [options]="devices"
        class="p-fluid mt-3"
        id="deviceId"
        optionLabel="label"
        optionValue="deviceId"
        placeholder="Select device..."
      ></p-dropdown>

      <ng-container *ngIf="!isRawAudioTranscriptionRunning(); else stopButton">
        <button (click)="onStartRawTranscription()"
                [disabled]="!deviceId"
                class="mt-3"
                label="Start"
                pButton
                type="button"
        ></button>
      </ng-container>

      <ng-template #stopButton>
        <button (click)="onStopRawTranscription()"
                [disabled]="!deviceId"
                class="mt-3"
                label="Stop"
                pButton
                type="button"></button>
      </ng-template>

      <div class="mt-3">Volume: {{ rawAudioSwl | number: '1.2-2' }}</div>
    </div>
  </div>
</div>
