<div class="flex">
  <p-card class="sm:col-4 sm:col-offset-4" header="Recover Password">
    <div class="p-fluid">
      <div class="field">
        <input id="login" pInputText placeholder="Email" type="text" />
      </div>
    </div>
    <p-footer>
      <div class="grid my-0 mx-0">
        <div class="sm:col-6 sm:col-offset-3">
          <button label="Reset password" pButton style="width: 100%" type="button"></button>
        </div>
      </div>
    </p-footer>
  </p-card>
</div>
