import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WINDOW } from '@ng-web-apis/common';
import FileSaver from 'file-saver';
import { ConfirmationService, MenuItem, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenubarModule } from 'primeng/menubar';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { Subscription, timer } from 'rxjs';
import { WebSocketMessage, WEBSOCKET_RECONNECT_DELAY } from '../app.common';
import { LiveSubApiService } from '../live-sub-api.service';

export class TranscriptConfigurationDefaults {
  static readonly DEFAULT_TRANSCRIPT_FONT_SIZE = 18;
}

export interface TranscriptConfiguration {
  transcriptFontSize: number;
}

@Component({
  selector: 'app-transcript-page',
  templateUrl: './transcript-page.component.html',
  styleUrls: ['./transcript-page.component.scss'],
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    MenubarModule,
    ButtonModule,
    ConfirmDialogModule,
    ToastModule,
    DialogModule,
    TabViewModule,
    InputNumberModule,
    FormsModule,
    SliderModule,
    SharedModule,
  ],
})
export class TranscriptPageComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly FONT_SIZE_MIN = 8;
  readonly FONT_SIZE_MAX = 32;
  readonly FONT_SIZE_STEP = 1;

  readonly TEXT_DELAY = 200;

  text: string;
  menuBarItems: MenuItem[];
  isSettingsDialogVisible = false;
  configuration: TranscriptConfiguration;

  private webSocket: WebSocket;
  private timerSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private apiService: LiveSubApiService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private http: HttpClient,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    this.title.setTitle(`LiveSub Transcript`);
  }

  private static createConfiguration(): TranscriptConfiguration {
    return {
      transcriptFontSize: TranscriptConfigurationDefaults.DEFAULT_TRANSCRIPT_FONT_SIZE,
    };
  }

  private static storageConfigurationKey(): string {
    return 'transcript:configuration';
  }

  ngOnInit(): void {
    const localStorageConfiguration = this.loadClientConfiguration();

    if (localStorageConfiguration) {
      this.configuration = localStorageConfiguration;
    } else {
      this.configuration = TranscriptPageComponent.createConfiguration();
    }

    this.menuBarItems = [
      {
        label: 'File',
        items: [
          { label: 'Download', icon: 'pi pi-fw pi-download', command: () => this.download() },
          { separator: true },
          { label: 'Settings', icon: 'pi pi-fw pi-cog', command: () => this.onMenuBarSettings() },
        ],
      },
    ];

    this.text = '';

    this.apiService.retrieveText().subscribe((value) => {
      this.text = value;

      this.messageService.add({
        severity: 'success',
        summary: 'Text loaded',
        detail: `${this.text.length} characters`,
      });

      this.createWebSocket();
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  resetSettings(): void {
    this.configuration = TranscriptPageComponent.createConfiguration();
  }

  dismissSettings(): void {
    this.isSettingsDialogVisible = false;
    this.storeClientConfiguration(this.configuration);
  }

  download() {
    const blob = new Blob([this.text], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, 'transcript.txt');
  }

  private createWebSocket(): void {
    this.webSocket = new WebSocket(this.buildWebSocketUrl());

    this.webSocket.onopen = () => {
      this.messageService.add({ severity: 'success', summary: 'Network connected' });
    };

    this.webSocket.onerror = () => {
      this.messageService.add({ severity: 'error', summary: 'Network error' });
    };

    this.webSocket.onclose = () => {
      this.messageService.add({ severity: 'warn', summary: 'Network disconnected' });

      timer(WEBSOCKET_RECONNECT_DELAY).subscribe(() => {
        this.createWebSocket();
      });
    };

    this.webSocket.onmessage = (ev) => {
      this.handleWebSocketMessage(JSON.parse(ev.data) as WebSocketMessage);
    };
  }

  private handleWebSocketMessage(message: WebSocketMessage): void {
    switch (message.message_type) {
      case 'reload': {
        this.scheduleReload();
        break;
      }

      default:
        break;
    }
  }

  private scheduleReload(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    this.timerSubscription = timer(this.TEXT_DELAY).subscribe((value) => {
      this.apiService.retrieveText().subscribe((value) => {
        this.text = value;
        this.timerSubscription = null;
      });
    });
  }

  private buildWebSocketUrl(): string {
    const protocol = this.window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    return protocol + this.window.location.host + `${this.baseHref}/api/websocket`;
  }

  private onMenuBarSettings(): void {
    this.isSettingsDialogVisible = true;
  }

  private storeClientConfiguration(configuration: TranscriptConfiguration): void {
    this.localStorage.setItem(TranscriptPageComponent.storageConfigurationKey(), JSON.stringify(configuration));
  }

  private loadClientConfiguration(): TranscriptConfiguration | null {
    const configurationString = this.localStorage.getItem(TranscriptPageComponent.storageConfigurationKey());

    if (!configurationString) {
      return null;
    }

    const configuration = JSON.parse(configurationString) as TranscriptConfiguration;

    if (configuration.transcriptFontSize === undefined) {
      configuration.transcriptFontSize = TranscriptConfigurationDefaults.DEFAULT_TRANSCRIPT_FONT_SIZE;
    }

    return configuration;
  }
}
