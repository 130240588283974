/* eslint-disable @typescript-eslint/naming-convention */
import { APP_BASE_HREF, DOCUMENT, NgIf } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WINDOW } from '@ng-web-apis/common';
import { escape } from 'he';
import { ColorPickerModule } from 'ngx-color-picker';
import { ConfirmationService, MenuItem, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Menu, MenuModule } from 'primeng/menu';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { fromEvent, interval, Observable, Subscription, timer } from 'rxjs';
import {
  Configuration,
  ConfigurationDefaults,
  DropDownOption,
  OutputIdentifier,
  Style,
  TextTools,
  WEBSOCKET_RECONNECT_DELAY,
  WebSocketMessage,
  WebSocketMessageDataConfiguration,
  WebSocketMessageDataSpeechTranscript,
  WebSocketMessageDataTextOverride,
  WebSocketMessageDataTextUpdate,
} from '../app.common';
import { LiveSubApiService } from '../live-sub-api.service';

export interface SampleText {
  name: string;
  text: string;
}

const FONT_FAMILY_LIST = [
  'sans-serif',
  'serif',
  'monospace',
  'DejaVu Sans',
  'DejaVu Sans Mono',
  'DejaVu Serif',
  'Droid Sans',
  'Droid Sans Mono',
  'Droid Serif',
  'Roboto',
  'Roboto Condensed',
  'Bedstead',
];

const FONT_WEIGHT_LIST = ['normal', 'bold'];

const TEXT_OVERRIDE_OPTIONS: SampleText[] = [
  {
    name: 'Lorem ipsum',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod arcu sit amet sem placerat, eu volutpat turpis dignissim. Sed vel augue dignissim, vulputate metus quis, lacinia augue. Suspendisse ullamcorper metus ipsum, quis pharetra metus cursus vitae. Donec scelerisque tincidunt risus, a rutrum lacus pharetra quis. Nam gravida euismod orci, sed lobortis nulla convallis id. Donec ut enim eu elit semper consectetur vitae sit amet risus. Sed nec lacus eu odio egestas molestie.',
  },
  {
    name: 'Meteo',
    text: 'Le previsioni meteo per oggi indicano cieli nuvolosi con possibilità di pioggia nel tardo pomeriggio. Le temperature massime saranno intorno ai 20 gradi Celsius, mentre le minime si attesteranno sui 15 gradi. Si consiglia di portare con sé un ombrello in caso di precipitazioni.',
  },
  {
    name: 'Internet',
    text: "Fearful of being left behind in the global economy, many governments have increased spending on infrastructure and education. But the Internet boom of the late 1990s and early 2000s showed that there's a more direct path to economic prosperity. Many countries still lack basic infrastructure such as roads, rail lines, and reliable power grids. But the Internet has already brought information and communication technology to hundreds of millions of people around the world. And with the rise of mobile technology, the Internet is now accessible to more people than ever before in history.",
  },
];

@Component({
  selector: 'app-transcript-page',
  templateUrl: './display-page.component.html',
  styleUrls: ['./display-page.component.scss'],
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    CardModule,
    TabViewModule,
    InputNumberModule,
    FormsModule,
    SliderModule,
    DropdownModule,
    ColorPickerModule,
    CheckboxModule,
    InputTextareaModule,
    SharedModule,
    ButtonModule,
    MenuModule,
    ConfirmDialogModule,
    ToastModule,
    DialogModule,
    InputTextModule,
    NgIf,
  ],
})
export class DisplayPageComponent implements OnInit, OnDestroy, AfterViewChecked {
  readonly FONT_SIZE_MIN = 8;
  readonly FONT_SIZE_MAX = 256;
  readonly FONT_SIZE_STEP = 1;

  readonly LINE_HEIGHT_MIN = 0.8;
  readonly LINE_HEIGHT_MAX = 2.0;
  readonly LINE_HEIGHT_STEP = 0.01;

  readonly BORDER_SIZE_MIN = 0;
  readonly BORDER_SIZE_MAX = 32;
  readonly BORDER_SIZE_STEP = 1;

  readonly OUTLINE_BORDER_SIZE_MIN = 0;
  readonly OUTLINE_BORDER_SIZE_MAX = 32;
  readonly OUTLINE_BORDER_SIZE_STEP = 1;

  readonly SPEECH_SAFEGUARD_DELAY = 3000;

  readonly PRESET_COLORS = ['#000000', '#ff0000', '#00ff00', '#ffff00', '#0000ff', '#ff00ff', '#00ffff', '#ffffff'];

  siteIdentifier: string;

  outputIdentifier: OutputIdentifier;
  configuration: Configuration;
  fontFamilyOptions: DropDownOption[];
  fontFamilyOption: DropDownOption;
  fontWeightOptions: DropDownOption[];
  fontWeightOption: DropDownOption;

  text: string;
  speech: string;
  textHtml: SafeHtml;
  speechHtml: SafeHtml;
  textOverride: string;

  isRestoreSettingsDialogVisible = false;
  dialogRestoreSettingsText = '';
  menuItems: MenuItem[];

  textOverrideOptions = TEXT_OVERRIDE_OPTIONS;
  selectedTextOverrideName: string;
  animationDelay: number;

  @ViewChild('output') output!: ElementRef;
  @ViewChild('menu') menu!: Menu;

  private readonly DEFAULT_ANIMATION_DELAY = 200;
  private readonly TEXT_THRESHOLD_COUNT = 2000;
  private readonly DRAGGING_OPACITY = 0.8;
  private readonly QUERY_PARAM_STATE = 'state';
  private readonly STATE_UPDATE_INTERVAL = 1000;
  private readonly PRESS_ACTIVATION_GUARD_TIME = 3000;
  private readonly fontFamilyList = FONT_FAMILY_LIST;
  private readonly fontWeightList = FONT_WEIGHT_LIST;
  private webSocket: WebSocket;
  private isSettingsPanelVisible = false;
  private isSettingsPanelActivatedByPress = false;
  private isSliderDragging = false;
  private scrollCheckRequired = false;
  private animationInProgress = false;
  private animationTextSource: string[] = [];
  private animationTextTarget: string[] = [];
  private animationTextOverrideCopy = '';

  private animationSubscription$: Subscription;
  private resizeObservable$: Observable<Event>;
  private resizeSubscription$: Subscription;
  private speechSafeguardSubscription$: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private apiService: LiveSubApiService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    this.animationDelay = this.DEFAULT_ANIMATION_DELAY;

    this.siteIdentifier = this.route.snapshot.paramMap.get('identifier');

    this.loadComponent();

    this.title.setTitle(`LiveSub: ${this.siteIdentifier}/${this.outputIdentifier}`);
  }

  private static createConfiguration(): Configuration {
    return {
      positionLeft: ConfigurationDefaults.DEFAULT_POSITION_LEFT,
      positionTop: ConfigurationDefaults.DEFAULT_POSITION_TOP,
      positionRight: ConfigurationDefaults.DEFAULT_POSITION_RIGHT,
      positionBottom: ConfigurationDefaults.DEFAULT_POSITION_BOTTOM,
      paddingLeft: ConfigurationDefaults.DEFAULT_PADDING_LEFT,
      paddingTop: ConfigurationDefaults.DEFAULT_PADDING_TOP,
      paddingRight: ConfigurationDefaults.DEFAULT_PADDING_RIGHT,
      paddingBottom: ConfigurationDefaults.DEFAULT_PADDING_BOTTOM,
      backgroundOpacity: ConfigurationDefaults.DEFAULT_BACKGROUND_OPACITY,
      fontFamily: ConfigurationDefaults.DEFAULT_FONT_FAMILY,
      fontWeight: ConfigurationDefaults.DEFAULT_FONT_WEIGHT,
      fontSize: ConfigurationDefaults.DEFAULT_FONT_SIZE,
      lineHeight: ConfigurationDefaults.DEFAULT_LINE_HEIGHT,
      textColor: ConfigurationDefaults.DEFAULT_TEXT_COLOR,
      backgroundColor: ConfigurationDefaults.DEFAULT_BACKGROUND_COLOR,
      borderSize: ConfigurationDefaults.DEFAULT_BORDER_SIZE,
      borderColor: ConfigurationDefaults.DEFAULT_BORDER_COLOR,
      outlineColor: ConfigurationDefaults.DEFAULT_OUTLINE_COLOR,
      outlineVisible: ConfigurationDefaults.DEFAULT_OUTLINE_VISIBLE,
      outlineBorderSize: ConfigurationDefaults.DEFAULT_OUTLINE_BORDER_SIZE,
      logoVisible: ConfigurationDefaults.DEFAULT_LOGO_VISIBLE,
      logoLeft: ConfigurationDefaults.DEFAULT_LOGO_LEFT,
      logoTop: ConfigurationDefaults.DEFAULT_LOGO_TOP,
      logoWidth: ConfigurationDefaults.DEFAULT_LOGO_WIDTH,
      logoHeight: ConfigurationDefaults.DEFAULT_LOGO_HEIGHT,
    };
  }

  private static encodeState(configuration: Configuration): string {
    return btoa(
      JSON.stringify({
        positionLeft: configuration.positionLeft,
        positionTop: configuration.positionTop,
        positionRight: configuration.positionRight,
        positionBottom: configuration.positionBottom,
        paddingLeft: configuration.paddingLeft,
        paddingTop: configuration.paddingTop,
        paddingRight: configuration.paddingRight,
        paddingBottom: configuration.paddingBottom,
        backgroundOpacity: configuration.backgroundOpacity,
        fontFamily: configuration.fontFamily,
        fontWeight: configuration.fontWeight,
        fontSize: configuration.fontSize,
        lineHeight: configuration.lineHeight,
        textColor: configuration.textColor,
        backgroundColor: configuration.backgroundColor,
        borderSize: configuration.borderSize,
        borderColor: configuration.borderColor,
        outlineColor: configuration.outlineColor,
        outlineVisible: configuration.outlineVisible,
        outlineBorderSize: configuration.outlineBorderSize,
        logoVisible: configuration.logoVisible,
        logoLeft: configuration.logoLeft,
        logoTop: configuration.logoTop,
        logoWidth: configuration.logoWidth,
        logoHeight: configuration.logoHeight,
      }),
    );
  }

  private static decodeState(stateString: string): Configuration {
    const stateObject = JSON.parse(atob(stateString)) as Configuration;

    if (stateObject.paddingLeft === undefined) {
      stateObject.paddingLeft = 0;
    }

    if (stateObject.paddingTop === undefined) {
      stateObject.paddingTop = 0;
    }

    if (stateObject.paddingRight === undefined) {
      stateObject.paddingRight = 0;
    }

    if (stateObject.paddingBottom === undefined) {
      stateObject.paddingBottom = 0;
    }

    if (stateObject.backgroundOpacity === undefined) {
      stateObject.backgroundOpacity = 0;
    }

    if (stateObject.outlineBorderSize === undefined) {
      stateObject.outlineBorderSize = ConfigurationDefaults.DEFAULT_OUTLINE_BORDER_SIZE;
    }

    return {
      positionLeft: stateObject.positionLeft,
      positionTop: stateObject.positionTop,
      positionRight: stateObject.positionRight,
      positionBottom: stateObject.positionBottom,
      paddingLeft: stateObject.paddingLeft,
      paddingTop: stateObject.paddingTop,
      paddingRight: stateObject.paddingRight,
      paddingBottom: stateObject.paddingBottom,
      backgroundOpacity: stateObject.backgroundOpacity,
      fontFamily: stateObject.fontFamily,
      fontWeight: stateObject.fontWeight,
      fontSize: stateObject.fontSize,
      lineHeight: stateObject.lineHeight,
      textColor: stateObject.textColor,
      backgroundColor: stateObject.backgroundColor,
      borderSize: stateObject.borderSize,
      borderColor: stateObject.borderColor,
      outlineColor: stateObject.outlineColor,
      outlineVisible: stateObject.outlineVisible,
      outlineBorderSize: stateObject.outlineBorderSize,
      logoVisible: stateObject.logoVisible,
      logoLeft: stateObject.logoLeft,
      logoTop: stateObject.logoTop,
      logoWidth: stateObject.logoWidth,
      logoHeight: stateObject.logoHeight,
    };
  }

  ngOnInit(): void {
    const queryStateConfiguration = this.getQueryState();

    if (queryStateConfiguration) {
      this.configuration = queryStateConfiguration;
    } else {
      const localStorageConfiguration = this.loadConfiguration();

      if (localStorageConfiguration) {
        this.configuration = localStorageConfiguration;
      } else {
        this.configuration = DisplayPageComponent.createConfiguration();
      }
    }

    this.updateQueryState();
    this.storeConfiguration(this.configuration);

    this.fontFamilyOptions = [];
    this.fontFamilyList.forEach((value) => this.fontFamilyOptions.push({ value, label: value }));
    this.updateFontFamilyObject();

    this.fontWeightOptions = [];
    this.fontWeightList.forEach((value) => this.fontWeightOptions.push({ value, label: value }));
    this.updateFontWeightObject();

    this.text = '';
    this.speech = '';
    this.textHtml = '';
    this.speechHtml = '';
    this.textOverride = '';

    this.reformatHtml();

    this.menuItems = [
      { label: 'Copy all settings', icon: 'pi pi-fw pi-copy', command: () => this.copySettings() },
      { label: 'Restore', icon: 'pi pi-fw pi-upload', command: () => this.restoreSettings() },
      { label: 'Reset', icon: 'pi pi-fw pi-trash', command: () => this.resetSettings() },
    ];

    this.apiService.retrieveCurrentTextSegment().subscribe((value) => {
      this.text = value;
      this.reformatHtml();

      this.createWebSocket();
    });

    this.resizeObservable$ = fromEvent<Event>(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.requireScrollCheck();
    });

    timer(0, this.STATE_UPDATE_INTERVAL).subscribe(() => {
      try {
        this.updateState();
      } catch (e) {
        console.error(e);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.speechSafeguardSubscription$) {
      this.speechSafeguardSubscription$.unsubscribe();
    }

    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }

    if (this.animationSubscription$) {
      this.animationSubscription$.unsubscribe();
    }
  }

  ngAfterViewChecked(): void {
    if (this.scrollCheckRequired) {
      this.scrollCheckRequired = false;
      this.scrollToBottom();
    }
  }

  getSettingsPanelStyle(): Style {
    return {
      opacity: this.isSliderDragging ? this.DRAGGING_OPACITY : 1.0,
      display: this.isSettingsPanelVisible ? 'block' : 'none',
    };
  }

  getContainerStyle(): Style {
    return {
      'background-color': this.outputIsKey() ? 'black' : this.configuration.backgroundColor,
      'font-smooth': this.outputIsKey() ? 'never' : 'auto',
    };
  }

  getBackgroundColor() {
    const dragVisible = !this.outputIsKey() && this.isSliderDragging;
    const level = this.configuration.backgroundOpacity;

    if (this.outputIsKey()) {
      return `rgb(${level},${level},${level})`;
    } else {
      if (dragVisible) {
        return `rgba(${level},${level},${level},0.5)`;
      } else {
        return 'transparent';
      }
    }
  }

  getOutputStyle(): Style {
    return {
      left: `${this.configuration.positionLeft + this.configuration.paddingLeft}px`,
      top: `${this.configuration.positionTop + this.configuration.paddingTop}px`,
      right: `${this.configuration.positionRight + this.configuration.paddingRight}px`,
      bottom: `${this.configuration.positionBottom + this.configuration.paddingBottom}px`,
      'z-index': 1,
      'font-family': this.configuration.fontFamily,
      'font-weight': this.configuration.fontWeight,
      'font-size': `${this.configuration.fontSize / 10}em`,
      'line-height': this.configuration.lineHeight,
      color: this.outputIsKey() ? 'white' : this.configuration.textColor,
      'font-smooth': this.outputIsKey() ? 'never' : 'auto',
      'background-color': this.getBackgroundColor(),
    };
  }

  getTopLeftRectangleStyle(): Style {
    const borderStyle = this.configuration.outlineVisible ? 'solid' : 'none';
    const borderWidth = this.configuration.outlineVisible ? `${this.configuration.outlineBorderSize}px` : '0';
    const borderColor = this.configuration.outlineVisible ? this.configuration.outlineColor : 'transparent';

    return {
      position: 'absolute',
      left: `${this.configuration.positionLeft}px`,
      top: `${this.configuration.positionTop}px`,
      width: `${this.configuration.paddingLeft}px`,
      height: `${this.configuration.paddingTop}px`,
      'background-color': this.getBackgroundColor(),
      'border-left-width': borderWidth,
      'border-left-style': borderStyle,
      'border-left-color': borderColor,
      'border-top-width': borderWidth,
      'border-top-style': borderStyle,
      'border-top-color': borderColor,
    };
  }

  getTopRightRectangleStyle(): Style {
    const borderStyle = this.configuration.outlineVisible ? 'solid' : 'none';
    const borderWidth = this.configuration.outlineVisible ? `${this.configuration.outlineBorderSize}px` : '0';
    const borderColor = this.configuration.outlineVisible ? this.configuration.outlineColor : 'transparent';

    return {
      position: 'absolute',
      right: `${this.configuration.positionRight}px`,
      top: `${this.configuration.positionTop}px`,
      width: `${this.configuration.paddingRight}px`,
      height: `${this.configuration.paddingTop}px`,
      'background-color': this.getBackgroundColor(),
      'border-right-width': borderWidth,
      'border-right-style': borderStyle,
      'border-right-color': borderColor,
      'border-top-width': borderWidth,
      'border-top-style': borderStyle,
      'border-top-color': borderColor,
    };
  }

  getBottomLeftRectangleStyle(): Style {
    const borderStyle = this.configuration.outlineVisible ? 'solid' : 'none';
    const borderWidth = this.configuration.outlineVisible ? `${this.configuration.outlineBorderSize}px` : '0';
    const borderColor = this.configuration.outlineVisible ? this.configuration.outlineColor : 'transparent';

    return {
      position: 'absolute',
      left: `${this.configuration.positionLeft}px`,
      bottom: `${this.configuration.positionBottom}px`,
      width: `${this.configuration.paddingLeft}px`,
      height: `${this.configuration.paddingBottom}px`,
      'background-color': this.getBackgroundColor(),
      'border-left-width': borderWidth,
      'border-left-style': borderStyle,
      'border-left-color': borderColor,
      'border-bottom-width': borderWidth,
      'border-bottom-style': borderStyle,
      'border-bottom-color': borderColor,
    };
  }

  getBottomRightRectangleStyle(): Style {
    const borderStyle = this.configuration.outlineVisible ? 'solid' : 'none';
    const borderWidth = this.configuration.outlineVisible ? `${this.configuration.outlineBorderSize}px` : '0';
    const borderColor = this.configuration.outlineVisible ? this.configuration.outlineColor : 'transparent';

    return {
      position: 'absolute',
      right: `${this.configuration.positionRight}px`,
      bottom: `${this.configuration.positionBottom}px`,
      width: `${this.configuration.paddingRight}px`,
      height: `${this.configuration.paddingBottom}px`,
      'background-color': this.getBackgroundColor(),
      'border-right-width': borderWidth,
      'border-right-style': borderStyle,
      'border-right-color': borderColor,
      'border-bottom-width': borderWidth,
      'border-bottom-style': borderStyle,
      'border-bottom-color': borderColor,
    };
  }

  getLeftBorderOutputStyle(): Style {
    const borderStyle = this.configuration.outlineVisible ? 'solid' : 'none';
    const borderWidth = this.configuration.outlineVisible ? `${this.configuration.outlineBorderSize}px` : '0';
    const borderColor = this.configuration.outlineVisible ? this.configuration.outlineColor : 'transparent';

    return {
      position: 'absolute',
      left: `${this.configuration.positionLeft}px`,
      top: `${this.configuration.positionTop + this.configuration.paddingTop}px`,
      width: `${this.configuration.paddingLeft}px`,
      bottom: `${this.configuration.positionBottom + this.configuration.paddingBottom}px`,
      'background-color': this.getBackgroundColor(),
      'border-left-width': borderWidth,
      'border-left-style': borderStyle,
      'border-left-color': borderColor,
    };
  }

  getRightBorderOutputStyle(): Style {
    const borderStyle = this.configuration.outlineVisible ? 'solid' : 'none';
    const borderWidth = this.configuration.outlineVisible ? `${this.configuration.outlineBorderSize}px` : '0';
    const borderColor = this.configuration.outlineVisible ? this.configuration.outlineColor : 'transparent';

    return {
      position: 'absolute',
      right: `${this.configuration.positionRight}px`,
      top: `${this.configuration.positionTop + this.configuration.paddingTop}px`,
      width: `${this.configuration.paddingRight}px`,
      bottom: `${this.configuration.positionBottom + this.configuration.paddingBottom}px`,
      'background-color': this.getBackgroundColor(),
      'border-right-width': borderWidth,
      'border-right-style': borderStyle,
      'border-right-color': borderColor,
    };
  }

  getTopBorderOutputStyle(): Style {
    const borderStyle = this.configuration.outlineVisible ? 'solid' : 'none';
    const borderWidth = this.configuration.outlineVisible ? `${this.configuration.outlineBorderSize}px` : '0';
    const borderColor = this.configuration.outlineVisible ? this.configuration.outlineColor : 'transparent';

    return {
      position: 'absolute',
      left: `${this.configuration.positionLeft + this.configuration.paddingLeft}px`,
      top: `${this.configuration.positionTop}px`,
      right: `${this.configuration.positionRight + this.configuration.paddingRight}px`,
      height: `${this.configuration.paddingTop}px`,
      'background-color': this.getBackgroundColor(),
      'border-top-width': borderWidth,
      'border-top-style': borderStyle,
      'border-top-color': borderColor,
    };
  }

  getBottomBorderOutputStyle(): Style {
    const borderStyle = this.configuration.outlineVisible ? 'solid' : 'none';
    const borderWidth = this.configuration.outlineVisible ? `${this.configuration.outlineBorderSize}px` : '0';
    const borderColor = this.configuration.outlineVisible ? this.configuration.outlineColor : 'transparent';

    return {
      position: 'absolute',
      left: `${this.configuration.positionLeft + this.configuration.paddingLeft}px`,
      right: `${this.configuration.positionRight + this.configuration.paddingRight}px`,
      bottom: `${this.configuration.positionBottom}px`,
      height: `${this.configuration.paddingBottom}px`,
      'background-color': this.getBackgroundColor(),
      'border-bottom-width': borderWidth,
      'border-bottom-style': borderStyle,
      'border-bottom-color': borderColor,
    };
  }

  getOutputBorderStyle(): Style {
    return {
      '-webkit-text-stroke-width': `${this.configuration.borderSize + (this.outputIsKey() ? 1 : 0)}px`,
      '-webkit-text-stroke-color': this.outputIsKey() ? 'white' : this.configuration.borderColor,
    };
  }

  getOutputTextStyle(): Style {
    return {
      '-webkit-text-stroke-width': `${this.configuration.borderSize}px`,
    };
  }

  getTranscriptTextBorderStyle(): Style {
    return {};
  }

  getTranscriptTextStyle(): Style {
    return {};
  }

  getTranscriptSpeechBorderStyle(): Style {
    return { display: this.speech.length > 0 ? 'inline' : 'none' };
  }

  getTranscriptSpeechStyle(): Style {
    return { display: this.speech.length > 0 ? 'inline' : 'none' };
  }

  onSliderChange(): void {
    this.isSliderDragging = true;
  }

  onSliderEnd(): void {
    this.isSliderDragging = false;
  }

  onBackgroundClick($event: MouseEvent): void {
    if (this.isSettingsPanelActivatedByPress) {
      return;
    }

    if (this.isSettingsPanelVisible) {
      this.dismissSettings();
    } else if ($event.altKey || $event.ctrlKey || $event.metaKey || $event.shiftKey) {
      this.isSettingsPanelVisible = true;
    }

    this.isSliderDragging = false; // failsafe for keyboard dragging
  }

  onBackgroundPress(): void {
    if (this.isSettingsPanelVisible) {
      return;
    }

    this.isSettingsPanelActivatedByPress = true;

    timer(this.PRESS_ACTIVATION_GUARD_TIME).subscribe(() => {
      this.isSettingsPanelActivatedByPress = false;
    });

    this.isSliderDragging = false;
    this.isSettingsPanelVisible = true;
  }

  resetSettings(): void {
    this.confirmationService.confirm({
      message: 'Reset settings?',
      acceptLabel: 'Reset',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-times',
      icon: 'pi pi-trash',
      accept: () => {
        this.configuration = DisplayPageComponent.createConfiguration();

        this.messageService.add({
          severity: 'success',
          summary: 'Settings restored to default',
        });
      },
    });
  }

  copySettings(): void {
    const stateString = DisplayPageComponent.encodeState(this.configuration);

    this.copyTextToClipboard(stateString);

    this.messageService.add({
      severity: 'success',
      summary: 'Settings copied to clipboard',
    });
  }

  restoreSettings(): void {
    this.isRestoreSettingsDialogVisible = true;
  }

  dismissSettings(): void {
    this.isSettingsPanelVisible = false;
    this.isSettingsPanelActivatedByPress = false;
    this.menu.hide();
    this.validateNumericFields();
    this.updateState();
    this.requireScrollCheck();
  }

  onFontFamilyChanged(): void {
    this.configuration.fontFamily = this.fontFamilyOption.value;
  }

  onFontWeightChanged(): void {
    this.configuration.fontWeight = this.fontWeightOption.value;
  }

  formatTextEngine(leftText: string, span: boolean, rightText: string): SafeHtml {
    const addWord = () => {
      let wordHtml = escape(wordText);

      if (span) {
        wordHtml = `<span id="word-${characterOffset}" data-offset="${wordOffset}" data-length="${wordText.length}" data-word="${wordText}">${wordHtml}</span>`;
      }

      words.push(wordHtml);
    };

    let characterOffset = 0;
    let wordOffset = 0;
    let wordText = '';

    const words: string[] = [];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < leftText.length; i++) {
      if (leftText[i] === ' ' || leftText[i] === '\n') {
        if (wordText.length > 0) {
          addWord();
          characterOffset += 1;
        }

        if (leftText[i] === '\n') {
          words.push('<br>');
        }

        wordText = '';
      } else {
        if (wordText.length === 0) {
          wordOffset = i;
        }

        wordText += leftText[i];
      }
    }

    if (wordText.length) {
      addWord();
    }

    if (!TextTools.isGlueRequired(leftText, rightText)) {
      words[words.length - 1] += escape(' ');
    }

    return this.sanitizer.bypassSecurityTrustHtml(words.join(' '));
  }

  outputIsMain(): boolean {
    return this.outputIdentifier === 'main';
  }

  outputIsFill(): boolean {
    return this.outputIdentifier === 'fill';
  }

  outputIsKey(): boolean {
    return this.outputIdentifier === 'key';
  }

  onTextOverrideModelChange(event: string): void {
    this.selectedTextOverrideName = null;
    this.updateTextOverride(event);
    this.transmitTextOverride();
  }

  onRestoreSettings(): void {
    const payload = this.dialogRestoreSettingsText.trim();

    this.configuration = DisplayPageComponent.decodeState(payload);

    this.isRestoreSettingsDialogVisible = false;

    this.messageService.add({
      severity: 'success',
      summary: 'Settings restored',
    });
  }

  copyTextToClipboard(text: string): void {
    try {
      navigator.clipboard
        .writeText(text)
        .then(function () {
          console.log('Successfully copied to clipboard');
        })
        .catch(function (error) {
          console.log('Could not copy text: ', error);
        });
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  }

  validRestoreSettingsText(): boolean {
    if (!this.isRestoreSettingsDialogVisible) {
      return false;
    }

    const payload = this.dialogRestoreSettingsText.trim();

    if (payload.length === 0) {
      return false;
    }

    try {
      DisplayPageComponent.decodeState(payload);
    } catch (e) {
      return false;
    }

    return payload !== '';
  }

  showTranscript(): void {
    this.router.navigate(['transcript']).then();
  }

  onTextOverrideDropdownChange() {
    this.updateTextOverride(this.selectedTextOverrideName);
    this.transmitTextOverride();
  }

  startAnimation() {
    this.animationInProgress = true;
    this.animationTextOverrideCopy = this.textOverride;
    this.animationTextSource = this.textOverride.split(' ');
    this.animationTextTarget = [];

    this.createAnimationSubscription();
  }

  stopAnimation() {
    this.destroyAnimationSubscription();
    this.animationInProgress = false;
    this.updateTextOverride(this.animationTextOverrideCopy);
    this.transmitTextOverride();
    this.animationTextSource = [];
    this.animationTextTarget = [];
    this.animationTextOverrideCopy = '';
  }

  isAnimationInProgress() {
    return this.animationInProgress;
  }

  canPerformAnimation(): boolean {
    return JSON.stringify(this.textOverride.split(' ')) !== JSON.stringify(['']);
  }

  validateNumericFields() {
    if (this.configuration.positionLeft === null || this.configuration.positionLeft === undefined) {
      this.configuration.positionLeft = 0;
    }

    if (this.configuration.positionTop === null || this.configuration.positionTop === undefined) {
      this.configuration.positionTop = 0;
    }

    if (this.configuration.positionRight === null || this.configuration.positionRight === undefined) {
      this.configuration.positionRight = 0;
    }

    if (this.configuration.positionBottom === null || this.configuration.positionBottom === undefined) {
      this.configuration.positionBottom = 0;
    }

    if (this.configuration.paddingLeft === null || this.configuration.paddingLeft === undefined) {
      this.configuration.paddingLeft = 0;
    }

    if (this.configuration.paddingTop === null || this.configuration.paddingTop === undefined) {
      this.configuration.paddingTop = 0;
    }

    if (this.configuration.paddingRight === null || this.configuration.paddingRight === undefined) {
      this.configuration.paddingRight = 0;
    }

    if (this.configuration.paddingBottom === null || this.configuration.paddingBottom === undefined) {
      this.configuration.paddingBottom = 0;
    }
  }

  private performAnimationStep() {
    if (this.animationTextTarget.length > this.TEXT_THRESHOLD_COUNT) {
      this.animationTextTarget = [];
    }

    const word = this.animationTextSource.shift();
    this.animationTextSource.push(word); // rotate
    this.animationTextTarget.push(word); // append
    this.updateTextOverride(this.animationTextTarget.join(' '));
    this.transmitTextOverride();
  }

  private createAnimationSubscription() {
    this.destroyAnimationSubscription();

    const animationDelay = this.animationDelay;

    this.animationSubscription$ = interval(this.animationDelay).subscribe(() => {
      if (this.animationDelay !== animationDelay) {
        this.destroyAnimationSubscription();
        this.createAnimationSubscription();
        return;
      }

      this.performAnimationStep();
    });
  }

  private destroyAnimationSubscription() {
    if (!this.animationSubscription$) {
      return;
    }

    this.animationSubscription$.unsubscribe();
    this.animationSubscription$ = null;
  }

  private updateTextOverride(textOverride: string) {
    if (textOverride === null || textOverride === undefined) {
      textOverride = '';
    }

    this.textOverride = textOverride;
    this.speech = '';

    this.reformatHtml();
    this.unscheduleSpeechSafeguard();
  }

  private transmitTextOverride() {
    if (!this.outputIsFill()) {
      return;
    }

    if (this.webSocket.readyState !== WebSocket.OPEN) {
      return;
    }

    const message: WebSocketMessage = {
      message_type: 'text_override',
      message_data: {
        site_identifier: this.siteIdentifier,
        text_override: this.textOverride,
      },
    };

    this.webSocket.send(JSON.stringify(message));
  }

  private isVisibleElement(element: HTMLElement): boolean {
    return element.offsetTop + element.offsetHeight > (this.output.nativeElement as HTMLElement).scrollTop;
  }

  private loadComponent(): void {
    // TODO: create 'component' type
    const component = this.route.snapshot.paramMap.get('component');

    switch (component) {
      case 'main':
        this.outputIdentifier = component as OutputIdentifier;
        break;

      case 'fill':
        this.outputIdentifier = component as OutputIdentifier;
        break;

      case 'key':
        this.outputIdentifier = component as OutputIdentifier;
        break;

      default:
        this.router.navigate(['display', this.siteIdentifier, 'main']).then();
        break;
    }
  }

  private createWebSocket(): void {
    console.log(`createWebSocket: ${this.buildWebSocketUrl()}`);

    this.webSocket = new WebSocket(this.buildWebSocketUrl());

    this.webSocket.onopen = (ev) => {
      console.log(`webSocket.onopen: ${JSON.stringify(ev)}`);
    };

    this.webSocket.onerror = (ev) => {
      console.log(`webSocket.onerror: ${JSON.stringify(ev)}`);
    };

    this.webSocket.onclose = (ev) => {
      console.log(`webSocket.onclose: ${JSON.stringify(ev)}`);

      timer(WEBSOCKET_RECONNECT_DELAY).subscribe(() => {
        this.createWebSocket();
      });
    };

    this.webSocket.onmessage = (ev) => {
      const websocketMessage = JSON.parse(ev.data) as WebSocketMessage;

      this.handleWebSocketMessage(websocketMessage);
    };
  }

  private handleWebSocketMessage(message: WebSocketMessage): void {
    switch (message.message_type) {
      case 'configuration': {
        this.handleConfigurationMessage(message.message_data as WebSocketMessageDataConfiguration);
        break;
      }

      case 'speech_transcript': {
        this.handleSpeechTranscriptMessage(message.message_data as WebSocketMessageDataSpeechTranscript);
        break;
      }

      case 'text_update': {
        this.handleTextUpdateMessage(message.message_data as WebSocketMessageDataTextUpdate);
        break;
      }

      case 'text_override': {
        this.handleTextOverrideMessage(message.message_data as WebSocketMessageDataTextOverride);
        break;
      }

      default:
        break;
    }
  }

  private handleConfigurationMessage(message: WebSocketMessageDataConfiguration): void {
    if (this.siteIdentifier !== message.site_identifier) {
      return;
    }

    if (!this.outputIsKey()) {
      return;
    }

    this.configuration = message.configuration;
    this.reformatHtml();
  }

  private handleTextUpdateMessage(message: WebSocketMessageDataTextUpdate): void {
    if (this.animationInProgress) {
      this.stopAnimation();
    }

    this.textOverride = '';
    this.selectedTextOverrideName = null;

    this.text = message.text;
    this.speech = '';

    this.reformatHtml();
  }

  private handleTextOverrideMessage(message: WebSocketMessageDataTextOverride): void {
    if (this.siteIdentifier !== message.site_identifier) {
      return;
    }

    if (!this.outputIsKey()) {
      return;
    }

    this.updateTextOverride(message.text_override);
  }

  private handleSpeechTranscriptMessage(message: WebSocketMessageDataSpeechTranscript): void {
    if (this.animationInProgress) {
      this.stopAnimation();
    }

    this.textOverride = '';
    this.selectedTextOverrideName = null;

    this.speech = message.text;

    this.reformatHtml();
    this.scheduleSpeechSafeguard();
  }

  private buildWebSocketUrl(): string {
    const protocol = this.window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    return protocol + this.window.location.host + `${this.baseHref}api/websocket`;
  }

  private updateState(): void {
    const stateString = DisplayPageComponent.encodeState(this.configuration);

    if (this.getQueryStateString() === stateString) {
      return;
    }

    this.requireScrollCheck();
    this.updateQueryStateString(stateString);
    this.storeConfiguration(this.configuration);
    this.uploadConfiguration();
  }

  private uploadConfiguration(): void {
    if (!this.outputIsFill()) {
      return;
    }

    if (this.webSocket.readyState !== WebSocket.OPEN) {
      return;
    }

    const message: WebSocketMessage = {
      message_type: 'configuration',
      message_data: {
        site_identifier: this.siteIdentifier,
        configuration: this.configuration,
      },
    };

    this.webSocket.send(JSON.stringify(message));
  }

  private storeConfiguration(configuration: Configuration): void {
    this.localStorage.setItem(this.storageConfigurationKey(), JSON.stringify(configuration));
  }

  private loadConfiguration(): Configuration | null {
    const configurationString = this.localStorage.getItem(this.storageConfigurationKey());

    if (!configurationString) {
      return null;
    }

    return JSON.parse(configurationString) as Configuration;
  }

  private storageConfigurationKey(): string {
    return `${this.siteIdentifier}:${this.outputIdentifier}`;
  }

  private updateQueryStateString(stateString: string): void {
    this.router
      .navigate([], {
        queryParams: { state: stateString },
        queryParamsHandling: 'merge',
      })
      .then();
  }

  private getQueryStateString(): string | null {
    return this.route.snapshot.queryParamMap.get(this.QUERY_PARAM_STATE);
  }

  private updateQueryState(): void {
    this.updateQueryStateString(DisplayPageComponent.encodeState(this.configuration));
  }

  private getQueryState(): Configuration | null {
    const stateString = this.getQueryStateString();

    if (!stateString) {
      return null;
    }

    return DisplayPageComponent.decodeState(stateString);
  }

  private requireScrollCheck(): void {
    this.scrollCheckRequired = true;
  }

  private scrollToBottom(): boolean {
    const element = this.output.nativeElement as HTMLElement;

    const nextScrollTop = element.scrollHeight - element.clientHeight;

    if (element.scrollTop !== nextScrollTop) {
      element.scrollTop = nextScrollTop;
      return true;
    }

    return false;
  }

  private updateFontFamilyObject(): void {
    this.fontFamilyOption = this.fontFamilyOptions.find((x) => x.value === this.configuration.fontFamily);
  }

  private updateFontWeightObject(): void {
    this.fontWeightOption = this.fontWeightOptions.find((x) => x.value === this.configuration.fontWeight);
  }

  private reformatHtml(): void {
    if (this.textOverride.length > 0) {
      this.textHtml = this.formatTextEngine(this.textOverride, false, '');
      this.speechHtml = this.formatTextEngine('', false, '');
    } else {
      this.textHtml = this.formatTextEngine(this.text, false, this.speech); // was: true
      this.speechHtml = this.formatTextEngine(this.speech, false, '');
    }

    this.requireScrollCheck();
  }

  private scheduleSpeechSafeguard(): void {
    this.unscheduleSpeechSafeguard();

    this.speechSafeguardSubscription$ = timer(this.SPEECH_SAFEGUARD_DELAY).subscribe(() => {
      this.speech = '';
      this.reformatHtml();
    });
  }

  private unscheduleSpeechSafeguard(): void {
    if (this.speechSafeguardSubscription$) {
      this.speechSafeguardSubscription$.unsubscribe();
    }
  }
}
