import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { LiveSubApiService } from '../live-sub-api.service';

export interface Credentials {
  email: string;
  password: string;
}

export interface TokenData {
  type: 'user';
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private apiService: LiveSubApiService,
  ) {}

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  removeToken(): void {
    localStorage.removeItem('token');
  }

  decodeToken(): TokenData {
    return new JwtHelperService().decodeToken(this.getToken());
  }

  login(credentials: Credentials): Observable<boolean> {
    this.removeToken();

    return new Observable<boolean>((subscriber) => {
      this.apiService.getToken(credentials.email, credentials.password).subscribe({
        next: (tokenResult) => {
          this.setToken(tokenResult.access_token);
          subscriber.next(true);
          subscriber.complete();
        },
        error: () => {
          subscriber.next(false);
          subscriber.complete();
        },
      });
    });
  }
}
