<style type="text/css"></style>

<div class="flex align-items-center justify-content-between px-3 livesub-navigation">
  <h1 style="font-family: sans-serif">LiveSub</h1>

  <div>
    <!-- placehoder for buttons -->
  </div>
</div>

<div class="flex m-0 flex-row">
  <div class="flex flex-column livesub-main">
    <div class="livesub-main-content p-0 auth-background">
      <div class="flex flex-column justify-content-center" style="flex: 1">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="flex justify-content-center align-items-center livesub-footer">
      <p style="font-family: sans-serif">&copy; 2020-2023 Universal Multimedia Access Srl</p>
    </div>
  </div>
</div>
