import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { Credentials } from '../auth/auth.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  standalone: true,
  imports: [CardModule, FormsModule, InputTextModule, ReactiveFormsModule, RouterLink, NgIf, SharedModule, ButtonModule],
})
export class LoginDialogComponent implements OnInit {
  @Input() errorMessage: string | null = null;

  @Output() loginEvent = new EventEmitter<Credentials>();

  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [Validators.required]);

  constructor() {}

  ngOnInit(): void {}

  login(): void {
    this.loginEvent.emit({
      email: this.email.value,
      password: this.password.value,
    });
  }
}
