import { Component, OnInit } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-password-recovery-dialog',
  templateUrl: './password-recovery-dialog.component.html',
  styleUrls: ['./password-recovery-dialog.component.scss'],
  standalone: true,
  imports: [CardModule, InputTextModule, SharedModule, ButtonModule],
})
export class PasswordRecoveryDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
