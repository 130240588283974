<div class="flex">
  <p-card class="sm:col-4 sm:col-offset-4" header="Welcome to LiveSub">
    <div class="p-fluid">
      <div class="field">
        <input [formControl]="email" id="login" pInputText placeholder="Login" type="text" />
      </div>
      <div class="field">
        <input [formControl]="password" id="password" pInputText placeholder="Password" type="password" />
        <p><a routerLink="/auth/password">Forgot your password?</a></p>
      </div>
      <p *ngIf="errorMessage" class="p-error text-center">{{ errorMessage }}</p>
    </div>
    <p-footer>
      <div class="grid my-0 mx-0">
        <div class="sm:col-6 sm:col-offset-3">
          <button (click)="login()" label="Sign in" pButton style="width: 100%" type="button"></button>
        </div>
      </div>
    </p-footer>
  </p-card>
</div>
